$grid-breakpoints-for-section: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1224px
);
.platter-container {
  // Use this size to indent the container faking one column.
  margin-inline: #{map-get($padding, "column-mobile")};
}
@each $breakpoint, $value in $grid-breakpoints-for-section {
  @include media-breakpoint-up($breakpoint) {
    .section-props,
    .section {
      --margin-inline: calc(((100vw - #{$value}) / 2) + #{map-get($padding, "column-desktop")});
      --plate-column-width: calc(#{$value} / 12);
    }

    .platter-container {
      // Use this size to indent the container faking one column.
      margin-inline: calc(((100vw - #{$value}) / 2) + #{map-get($padding, "column-desktop")});
    }
  }
}

.section {
  position: relative;
  .section__inner {
    position: relative;
    z-index: 1;
  }
  &:target {
    animation: highlight 1s ease-in-out 0.5s;
  }
  &--center {
    .plate--column {
      justify-content: center;
    }
  }

  &--balance {
    @include media-breakpoint-up($bpUp) {
      .plate--row {
        flex-wrap: nowrap;
      }

      .plate--column {
        min-width: unset;
        width: unset;
        max-width: unset;
        flex-grow: 1;
        flex-basis: unset;
        flex-shrink: 1;
      }
    }
  }

  // Section backgrounds.
  &.section-background-lightgrey {
    --section-background-color: var(--asg-lichtgrijs, var(--lightgrey));
  }

  &.section-background-gradient {
    --section-background-color: var(--section-theme-gradient);
    &:not([class*="section-theme-opacity-0"]) {
      --text-color: white;
    }
  }

  &.section-background-color-1 {
    --section-background-color: var(--first-color, var(--asg-paars));
    &:not([class*="section-theme-opacity-0"]) {
      --text-color: white;
    }
  }
  &.section-background-color-2 {
    --section-background-color: var(--second-color, var(--asg-groen));
    &:not([class*="section-theme-opacity-0"]) {
      --text-color: white;
    }
  }

  &.section-theme-opacity-1 {
    --section-theme-opacity: 1;
  }
  &.section-theme-opacity-0_2 {
    --section-theme-opacity: 0.2;
  }
  &.section-theme-opacity-0_1 {
    --section-theme-opacity: 0.1;
  }

  // Backgrounds.
  // &:not(.download-section)::before {
  //   content: "";
  //   position: absolute;
  //   inset: 0;
  //   background: var(--section-background-color, transparent);
  //   opacity: var(--section-theme-opacity, 1);
  // }

  // Clippaths.
  .section-background {
    @include cover;
  }

  &.download-section .download-section-inner-background,
  .section-background {
    opacity: var(--section-theme-opacity);
    background: var(--section-background-color);
    .clip-path {
      aspect-ratio: 62 / 5;
      width: 100%;
      left: 0;
      position: absolute;
      background: var(--section-background-color);
    }
  }
  &.section-clip-rounded-top,
  &.section-clip-wave-top {
    position: relative;
    .download-section-inner-background .clip-path,
    .section-background .clip-path {
      top: 1px;
      transform: translateY(-100%);
    }
  }
  &.section-clip-rounded-bottom,
  &.section-clip-wave-bottom {
    position: relative;
    .download-section-inner-background .clip-path,
    .section-background .clip-path {
      bottom: 1px;
      transform: translateY(100%);
    }
  }
  &.section-clip-rounded-bottom,
  &.section-clip-rounded-top {
    background: unset;
  }
  &.section-clip-rounded-top {
    .download-section-inner-background .clip-path {
      @include media-breakpoint-up($bpUp) {
        clip-path: url(#section_rounded_top);
      }
    }
    .section-background .clip-path {
      clip-path: url(#section_rounded_top);
    }
  }
  &.section-clip-rounded-bottom {
    .download-section-inner-background .clip-path {
      @include media-breakpoint-up($bpUp) {
        clip-path: url(#section_rounded_bottom);
      }
    }
    .section-background .clip-path {
      clip-path: url(#section_rounded_bottom);
    }
  }

  // Wavy one.
  &.section-clip-wave-bottom,
  &.section-clip-wave-top {
    position: relative;
    background: unset;
  }
  &.section-clip-wave-top {
    &.download-section .download-section-inner-background .clip-path {
      @include media-breakpoint-up($bpUp) {
        clip-path: url(#section_wave_top);
      }
    }
    .section-background .clip-path {
      // clip-path: url(#section_wave_top_mobile);
      clip-path: url(#section_wave_top);

      @include media-breakpoint-up($bpUp) {
        clip-path: url(#section_wave_top);
      }
    }
  }
  &.section-clip-wave-bottom {
    &.download-section .download-section-inner-background .clip-path {
      @include media-breakpoint-up($bpUp) {
        clip-path: url(#section_wave_bottom);
      }
    }
    .section-background .clip-path {
      clip-path: url(#section_wave_bottom);
    }
  }
}

/* Animation for highlighting */
@keyframes highlight {
  0% {
    background-color: #fff; /* Start with the default background color */
  }
  50% {
    background-color: #ffe58a; /* Light yellow color */
  }
  100% {
    background-color: #fff; /* End with the default background color */
  }
}
