.filter__wrapper--schools {
  margin-block-end: 10rem;
  @include containerGrid;
  #filter-app {
    grid-column: column-one / column-twelve;
  }
}
.post-list {
  container-name: post-list;
  container-type: inline-size;

  // Special grid, so to allow for spanning 2 columns per card for example a quote card.
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  --gap: 1.8rem;
  gap: var(--gap);

  &.schools {
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.agenda_items,
  &.articles {
    max-width: 79rem;
    margin: 3rem auto;

    @include media-breakpoint-down($bpDown) {
      --gap: 0;
      .list-item:nth-child(odd) {
        background: var(--asg-lichtgrijs);
      }
    }
  }
  //grid-auto-flow: dense; /* make sur to fill all the area */

  .list-item {
    --columns-span: 1;
    grid-column: span var(--columns-span);

    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
      > .job-card {
        height: 100%;
      }
    }
  }
}

.school-teaser,
.post-teaser {
  position: relative;
  .teaser-svg {
    position: absolute;
    z-index: 5;
    width: 8.3rem;
    height: 8.3rem;
    inset-inline-end: 0;
    inset-block-start: 0;
    fill: white;
    opacity: 0.5;
  }
}

.school-teaser {
  text-decoration: none; // reset link.
  overflow: hidden;
  border-radius: 3rem;
  position: relative;

  display: grid;
  grid-template-areas: "school";

  .school-image,
  .school-teaser-content {
    grid-area: school;
  }

  .school-teaser-content {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    z-index: 11;
    align-self: flex-end;
    color: white;

    h2 {
      font-size: 4.4rem;
      line-height: 1;
    }

    .types {
      font-size: 1.6rem;
      line-height: calc(18 / 16);
      letter-spacing: 0.16rem;
      text-transform: uppercase;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 4;
    inset: 0;
    background: linear-gradient(90deg, rgba(7, 42, 73, 0) 0.24%, rgba(16, 101, 175, 0.5) 99.33%);
  }
}

.article-result {
  text-decoration: none;
  color: inherit;

  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: flex-start;
  grid-template-rows: 1fr;
  grid-auto-flow: row;

  .mechano {
    grid-area: 1 / 1 / 2 / 2;
  }
  .content {
    grid-area: 1 / 2 / 2 / 3;
  }

  gap: 2rem;
  padding: 3rem;
  @include media-breakpoint-up($bpUp) {
    padding: 3rem;
    gap: 4rem;
  }

  .content {
    display: grid;
    grid-template-areas: "title title" "text text" "category arrow";

    @include media-breakpoint-up($bpUp) {
      grid-template-areas: "title title title" "category text arrow";
      grid-template-columns: 0.23fr 1fr auto;
      column-gap: 2rem;
    }

    h2 {
      grid-area: title;
      margin-block-end: var(--vertical-flow);
    }
    .index-text {
      grid-area: text;
      margin-block-end: var(--vertical-flow);
    }
    .article-result-category {
      grid-area: category;
      align-self: flex-start;
    }
    .article-result-arrow {
      grid-area: arrow;
    }
  }

  .mechano {
    display: grid;
    grid-template-areas: "mechano";
    justify-content: center;
    width: 2rem;
    @include media-breakpoint-up($bpUp) {
      width: 3.5rem;
    }
    .mechano-1,
    .mechano-2 {
      grid-area: mechano;
      display: flex;
      aspect-ratio: 1/3;
      background: var(--theme-gradient);
      clip-path: url(#svg_header_vertical_clip);
      position: relative;
      width: 2rem;
      @include media-breakpoint-up($bpUp) {
        width: 3.5rem;
      }
    }
    .mechano-1 {
      z-index: 2;
    }
    .mechano-2 {
      transform: translateY(32%);
      opacity: 0.5;
    }
  }
  // First only.
  .article-result-category {
    color: rgb(19, 46, 99);
    font-size: 1.4rem;
    padding: 1.4rem 1.8rem;
    border-radius: 3rem;
    border: 1px solid currentColor;

    display: flex;
    justify-self: flex-start;
  }
  .article-result-arrow {
    fill: var(--theme-article-result-arrow, var(--asg-groen));
    width: 4rem;
    height: 4rem;
    justify-self: flex-end;
  }
}

.index-featured-items {
  margin-inline: var(--margin-inline, 3rem);
  margin-block-start: -10rem;
  margin-block-end: 3rem;
  position: relative;
  @include z-index(header_overflow);
}
