.vacancies-overview {
  .filter-container {
    grid-template-columns: 1fr;
    display: grid;
    border-radius: 3.5rem;
    background-color: var(--basicgrey);
    @include media-breakpoint-up($bpUp) {
      grid-template-columns: 3fr 9fr;
    }
    .filter-input {
      display: block;
      padding: 2.5rem;
      @include media-breakpoint-up($bpUp) {
        padding: 10rem 2.4rem 5rem;
      }
      .filter-input-branding {
        margin: 0 0 5.4rem;
        svg {
          width: 13rem;
          height: 7.3rem;
        }
      }
      p {
        color: var(--asg-blauw);
        margin: 0 0 1.6rem;
      }
      .filter__items__title {
        background-color: var(--asg-blauw);
        padding: 1.4rem 2.4rem;
        border-radius: 5rem;
        color: var(--white);
        gap: 1.2rem;
        cursor: pointer;
        @include flex-c;
        svg {
          @include box(1.5rem);
          &:last-child {
            transform: rotate(180deg);
          }
        }
      }
      .filter-checkbox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.3rem;
      }
      .filter__items__list {
        justify-content: flex-start;
        overflow: hidden;
        padding-left: 4rem;
        &__wrapper {
          display: grid;
          grid-template-rows: 0fr;
          transition: grid-template-rows 0.3s;
          &.is-open {
            grid-template-rows: 1fr;
          }
        }
        .filter__items__list__item {
          cursor: pointer;
          input,
          label {
            cursor: pointer;
          }
          label {
            border: none;
            color: var(--asg-blauw);
            background-color: #d4d4d4;
            font-size: 1.6rem;
            transition:
              background-color 0.3s,
              color 0.3s;
          }
          &--selected label {
            background-color: var(--asg-blauw);
            color: var(--white);
          }
        }
      }
    }
    .filter-content {
      background: var(--theme-gradient);
      padding: 3.6rem 3.4rem;
      border-radius: 3.5rem;
      @include media-breakpoint-up($bpUp) {
        padding: 4.4rem;
      }
      .selector-wrapper--sort {
        display: table;
        margin: 0 0 1.8rem;
        select {
          background-color: var(--asg-paars);
          color: var(--white);
          border: none;
          background: url("data:image/svg+xml,%3Csvg fill='none' height='7' viewBox='0 0 12 7' width='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='m1.57764 1.01367 4.375 4.375 4.37496-4.375' stroke='%235d5a88'/%3E%3Cpath d='m1.57764 1.01367 4.375 4.375 4.37496-4.375' stroke='%2318277a'/%3E%3Cpath d='m1.57764 1.01367 4.375 4.375 4.37496-4.375' stroke='%23ffffff'/%3E%3C/g%3E%3C/svg%3E"),
            var(--asg-paars);
          background-repeat: no-repeat;
          background-position: right 1.6rem center;
          background-size: 1.5rem;
          &:focus {
            outline: none;
          }
        }
      }
      .pagination {
        justify-content: center;
        ul.pagination {
          margin: 4rem 0 0;
          gap: 2rem;
          position: relative;
          &:after {
            content: "";
            height: calc(100% + 2.8rem);
            top: -1.4rem;
            border: solid 1px var(--white);
            border-radius: 5rem;
            width: calc(100% + 4.8rem);
            left: -2.4rem;
            position: absolute;
            pointer-events: none;
          }
          .pagination__item {
            @include box(2.8rem);
            @include flex-cc;
            border-radius: 50%;
            border: solid 1px var(--white);
            background-color: var(--white);
            font-weight: 500;
            cursor: pointer;
            color: var(--asg-blauw);
            transition: background-color 0.3s;
            &--prev {
              transform: rotate(180deg);
            }
            &--next,
            &--prev {
              background-color: transparent;
              border: none;

              svg path {
                fill: var(--white);
                stroke: var(--white);
              }
            }
            &--active,
            &:hover {
              background-color: transparent;
              color: var(--white);
            }
          }
        }
      }
    }
  }
}
