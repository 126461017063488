.filter__input__range {
	padding-bottom: 2rem;
	position: relative;

	&__bar {
		height: 1rem;
		position: relative;
		background-color: lightgrey;
		// width: 100%;
		margin: 0 1rem;

		&__max,
		&__min {
			@include box(map-get($padding, 'small'));
			transform: translateY(-50%) translateX(-50%);
			border-radius: 50%;
			background-color: var(--primary-color);
			z-index: 2;
		}

		&__range {
			height: map-get($padding, 'small');;
			background: lightblue;
			transform: translateY(-50%);
		}

		&__max,
		&__min,
		&__range {
			position: absolute;
			top: 50%;
		}

		&__min {
			left: 0;
		}

		&__max {
			left: 100%;
		}
	}

	&__selector {
		position: absolute;
		z-index: 10;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
		height: map-get($padding, 'small');;

		input {
			pointer-events: none;
			position: absolute;
			width: 100%;
			left: 0;
			top: 0;
			opacity: 0;
			cursor: pointer;
		}

		&--active {
			pointer-events: all !important;
		}
	}
}