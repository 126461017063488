.plate--element + .plate--element__testimonials_element {
  margin-block-start: calc(var(--vertical-flow) * 2);
}
.testimonials-element {
  // Special grid, so to allow for spanning 2 columns per card for example a quote card.
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  --gap: 2.8rem;
  gap: var(--gap);
}
@container element (min-width: 55rem) {
  .testimonials-element {
    grid-template-columns: repeat(2, 1fr);
  }
}
@container element (min-width: 95rem) {
  .testimonials-element {
    grid-template-columns: repeat(3, 1fr);
  }
}

.testimonial {
  border: 1px solid currentColor;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;

  .title-wrapper {
    display: flex;
    gap: var(--vertical-flow);

    .mechano {
      fill: var(--asg-grijs);

      svg {
        width: 2.2rem;
        aspect-ratio: 1/3;
      }
    }

    .title {
      color: var(--theme-testimonial-title-color, var(--first-color));
      @extend .h2;
      margin-block-end: 0.6rem;
    }
  }
  .subtitle {
    font-size: 1.6rem;
    @extend .uppercase;
  }
}

[data-style-theme="corporate"],
[data-style-theme="school-circle"],
[data-style-theme="school-wave"] {
  .testimonial {
    border-radius: var(--theme-border-radius, 2.4rem);
  }
}
