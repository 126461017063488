.is-detail.job_posting {
  header {
    display: grid;
    grid-template-areas: "header" "tags";
    position: relative;
    .header {
      &-image,
      &-content {
        grid-area: header;
      }
      &-image {
        img {
          object-fit: cover;
          @include box(100%);
        }
      }
      &-content {
        z-index: 1;
        @include flex-cc;
        padding: 11rem 1.5rem 8rem;
        color: var(--white);
        position: relative;
        @include media-breakpoint-up(sm) {
          padding: 11rem var(--margin-inline, 5.35rem) 8rem;
        }
        &-title {
          text-align: center;
        }
        &:after {
          z-index: -1;
          content: "";
          @include cover;
          background: rgba(0, 0, 0, 0.2);
        }
      }
      &-tags {
        grid-area: tags;
        z-index: 1;
        &-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 0.9rem 1.5rem;
          transform: translateY(-50%);
        }
      }
    }
  }
  .job-content {
    &-desc {
      @include media-breakpoint-up(xl) {
        margin: 0 calc(100% / 8);
      }
      .paragraph + .paragraph {
        margin-top: 1.6rem;
      }
    }
    &-contact {
      background: var(--theme-gradient);
      color: var(--white);
      padding: 2.6rem 4rem;
      border-radius: 3.5rem;
      &-title {
        @extend h3;
      }
      &-info a {
        color: var(--white);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 1.3rem;
      margin: 5rem 0;
      @include media-breakpoint-up($bpUp) {
        margin: 10rem 0;
      }
      .button {
        justify-content: center;
        @include media-breakpoint-down($bpDown) {
          width: 100%;
        }
        &.apply {
          flex: 1 1 100%;
        }
      }
    }
  }
  .video-impression-section {
    .video-impression {
      border-radius: 0;
      &-wrapper {
        .label {
          margin-right: var(--margin-inline, 3rem);
          right: 0;
        }
        .content {
          margin: auto var(--margin-inline, 3rem) auto 0;
        }
      }
    }
  }
  .school-cta {
    &-image {
      border-radius: 3.5rem 3.5rem 0 0;
      overflow: hidden;
    }
    &-content {
      margin: -4rem 0 0;
      position: relative;
      color: var(--white);
      border-radius: 3.5rem;
      text-align: center;
      padding: 6.4rem 3.2rem 6rem;
      background: var(--theme-gradient);
    }
    &-quote {
      @extend h2;
      margin: 0 0 1.6rem;
      &-author {
        margin: 0 0 1.6rem;
        span {
          display: block;
        }
      }
    }
    &-bottom {
      margin: 4rem 0 0;
      display: flex;
      flex-direction: column;
      .website {
        @extend h3;
        text-decoration: none;
        color: var(--white);
        margin: 1.6rem 0 0;
        display: block;
      }
    }
  }
  .contact-section {
    background: var(--theme-gradient);
    padding: 5rem 0;
    .contact-section-quote-text {
      color: var(--white);
    }
    .contact-section-branding img {
      filter: grayscale(1) brightness(5);
    }
  }
  .section-school-info {
    background-color: var(--basicgrey);
    padding: 3.6rem 0;
    &-wrapper {
      display: grid;
      gap: 3rem 1.5rem;
      grid-template-areas: "logo" "map" "info";
      grid-template-columns: minmax(0, 1fr);
      @include media-breakpoint-up(sm) {
        grid-template-areas: "logo map" "info info";
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @include media-breakpoint-up($bpUp) {
        gap: 1rem 3rem;
        grid-template-areas: "logo info" "map info";
      }
      @include media-breakpoint-up(lg) {
        grid-template-columns: 3fr 4fr 6fr;
        grid-template-areas: "logo map info";
      }
      @include media-breakpoint-up(xl) {
        gap: 1rem 5rem;
      }
    }
    .logo {
      grid-area: logo;
    }
    .map {
      grid-area: map;
      border-radius: 2rem;
      overflow: hidden;
      img {
        object-fit: cover;
      }
    }
    .school-info {
      grid-area: info;
      display: flex;
      gap: 2rem;
      flex-wrap: wrap;
      @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
      }
      .school-buttons {
        align-items: center;
        flex: 1 1 100%;
      }
      .school-address {
        background-color: var(--asg-blauw);
        padding: 2.4rem;
        border-radius: 2.5rem;
        color: var(--white);
        width: 100%;
        display: flex;
        &-content {
          padding: 0 0 0 2.5rem;
          margin: auto 0;
          position: relative;
          svg {
            @include box(1.5rem);
            position: absolute;
            left: 0;
            top: 0.5rem;
          }
        }
      }
    }
  }
  .branding-section {
    padding: 4rem 0;
    @include media-breakpoint-up($bpUp) {
      padding: 8rem 0 4rem;
    }
  }
  .featured-vacancy {
    margin: 7rem 0 0;
    z-index: 1;
    .section-background {
      background-color: var(--basicgrey);
      @include cover;
    }
    & + .branding-section {
      margin: -6rem 0 0;
      @include media-breakpoint-up($bpUp) {
        padding: 14rem 0 4rem;
      }
    }
  }
}
