.article-section {
  &-content {
    border-radius: 3rem;
    padding: 5rem 2.5rem;
    color: var(--copy-color);
    @include media-breakpoint-up($bpUp) {
      padding: 8rem 10rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 8rem 20rem;
    }
    &.background-lightgrey {
      background-color: var(--asg-lichtgrijs, var(--lightgrey));
    }
    &-title {
      margin: 0 0 3.6rem;
      text-align: center;
    }
    &-text {
      .plate--container {
        padding: 0;
      }
    }
    &-specs {
      display: flex;
      gap: 2rem 4rem;
      align-items: center;
      justify-content: center;
      margin: 0 0 3.6rem;
      flex-wrap: wrap;
      .category {
        border-radius: 3rem;
        padding: 1.4rem 1.8rem;
        border: solid 1px var(--copy-color);
      }
      .author {
        text-transform: uppercase;
        font-size: 1.6rem;
        text-align: center;
        @include media-breakpoint-up(sm) {
          text-align: left;
        }
      }
    }
    &-buttons {
      margin: 5rem 0 0;
    }
  }
}
