.photo-gallery {
  &-grid {
    display: grid;
    grid-template-rows: repeat(13, 1fr);
    grid-template-columns: repeat(10, 1fr);
    gap: 1.4rem 0;
    @include media-breakpoint-up($bpUp) {
      grid-template-rows: repeat(6, 1fr);
      grid-template-columns: repeat(11, 1fr);
      gap: 2rem;
    }
    @include media-breakpoint-up(lg) {
      grid-template-rows: repeat(7, 1fr);
      gap: 2rem 4rem;
    }
    &-image {
      border-radius: 2rem;
      overflow: hidden;
      img {
        @include box(100%);
        object-fit: cover;
      }
      &:nth-child(1) {
        grid-row: 1 / 3;
        grid-column: 6 / 10;
        aspect-ratio: 1;
        margin: auto 0 0;
      }
      &:nth-child(2) {
        grid-row: 2/5;
        grid-column: 1/7;
        z-index: 1;
        aspect-ratio: 1;
      }
      &:nth-child(3) {
        grid-row: 3/11;
        grid-column: 2 / 10;
      }
      &:nth-child(4) {
        grid-row: 9/12;
        grid-column: 5 / 11;
        z-index: 1;
        aspect-ratio: 1;
      }
      &:nth-child(5) {
        grid-row: 11/13;
        grid-column: 1 / 10;
      }
    }
    .button-group {
      grid-row: 13 / 14;
      grid-column: 1 / 11;
      align-items: center;
      justify-content: center;
    }
    @include media-breakpoint-up($bpUp) {
      &-image {
        border-radius: 2rem;
        overflow: hidden;
        aspect-ratio: unset !important;
        &:nth-child(1) {
          grid-row: 2 / 4;
          grid-column: 1 / 5;
        }
        &:nth-child(2) {
          grid-row: 4 / 8;
          grid-column: 1 / 5;
        }
        &:nth-child(3) {
          grid-row: 1 / 7;
          grid-column: 5 / 8;
        }
        &:nth-child(4) {
          grid-row: 2 / 6;
          grid-column: 8 / 12;
        }
        &:nth-child(5) {
          grid-row: 6 / 8;
          grid-column: 8 / 12;
        }
      }
      .button-group {
        grid-row: 7 / 8;
        grid-column: 5 / 8;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
