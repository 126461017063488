.reference-section {
  background: var(--section-background-color);
  &-wrapper {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up($bpUp) {
      flex-direction: row;
    }
  }
  &-image {
    margin: -5rem 0 0;
    z-index: -1;
    @include media-breakpoint-up($bpUp) {
      flex: 0 0 calc(33% + 9rem);
      margin: 0 -9rem 0 0;
    }
    img {
      border-radius: 3.5rem 3.5rem 0 0;
      @include box(100%);
      object-fit: cover;
      aspect-ratio: 39 / 28;
      @include media-breakpoint-up($bpUp) {
        border-radius: 3.5rem 0 0 3.5rem;
        aspect-ratio: unset;
      }
    }
  }
  &-inner {
    background: var(--theme-gradient);
    border-radius: 2.5rem;
    overflow: hidden;
    padding: 6.4rem 5rem 3.2rem;
    display: flex;
    gap: 3rem 9rem;
    margin: -6.4rem 0 0;
    flex-direction: column;
    @include media-breakpoint-up($bpUp) {
      padding: 5rem;
      margin: 0;
      flex-direction: row;
      align-items: center;
    }
    @include media-breakpoint-up(lg) {
      padding: 7rem 10rem;
    }
    .content {
      display: grid;
      grid-template-areas: "quote" "author" "button";
      text-align: center;
      gap: 1rem;
      grid-template-rows: auto;
      @include media-breakpoint-up($bpUp) {
        text-align: left;
        grid-template-rows: auto min-content;
        grid-template-areas: "quote quote" "author button";
      }
    }
    .quote-text {
      grid-area: quote;
      color: var(--white);
      padding: 0;
      gap: 1rem 3.6rem;
      @extend h1;
      font-size: 3.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 5.6rem;
      }
    }
    .quote-author {
      color: var(--white);
      grid-area: author;
      span {
        display: block;
      }
    }
    .button-group {
      grid-area: button;
      justify-content: center;
      margin: 3.5rem 0 0;
      align-items: flex-end;
      @include media-breakpoint-up($bpUp) {
        justify-content: flex-end;
        margin: 0;
      }
    }
  }
  &-branding {
    @include flex-cc;
    flex-direction: column;
    @include media-breakpoint-up($bpUp) {
      flex: 0 0 33%;
    }
    img {
      filter: grayscale(1) brightness(5);
    }
    .button-group {
      margin: 5rem 0 0;
    }
  }
}
