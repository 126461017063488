.newsletter-form {
    &-form {
        display: grid;
        grid-template-areas:
            "input "
            "button"
            "helper";
        row-gap: 2rem;

        @include media-breakpoint-up($bpUp) {
            row-gap: 0;
            grid-template-areas: 'input button' 'notification notification' 'helper helper';
        }
        grid-template-columns: auto 0fr;
        .newsletter-input {
            grid-area: input;

            input {

            }
        }
        .button-group {
            grid-area: button;
        }
        .contact_form__helper {
            grid-area: helper;
            margin-block: 2rem 0;
            padding-block-start: 2rem;
            @include media-breakpoint-up($bpUp) {
                border-block-start: 2px solid var(--darkgrey);
            }

            display: flex;
            align-items: center;
            gap: 1.2rem;
            svg {
                fill: var(--theme-checklist-fill);
                width: 3rem;
                height: 3rem;
                flex: 0 0 auto;
            }
        }
    }

    .newsletter-input {
        display: grid;
        grid-template-areas: 'input';
        align-items: center;
        label {
            grid-area: input;
            margin: 0;
            text-transform: initial;
            transition: transform .2s ease-out;
        }
        input {
            grid-area: input;
            appearance: none;
            border: none;
            background: none;
            height: 100%;
            padding: 2rem 0;
            border-bottom: 2px solid var(--darkgrey);
            @include media-breakpoint-up($bpUp) {
                border: none;
            }


            &::placeholder {
                opacity: 0;
            }
            /* Hide label when input is focused or not empty */
            &:focus + label,
            &:valid + label,
            &:not(:placeholder-shown) + label {
                transform: translateY(-3rem);
            }
        }

    }
    .notification {
        grid-area: notification;
        margin: 1rem 0;
    }
}
