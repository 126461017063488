.usp-section {
  &-content {
    position: relative;
    margin: 0 0 5rem;
    padding: 0 2rem 0 5rem;
    @include media-breakpoint-up($bpUp) {
      margin: auto 5rem 5rem 0;
      padding: 0 0 0 5rem;
    }
    @include media-breakpoint-up(lg) {
      margin: auto 5rem auto 0;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }
    &-title {
      margin: 0 0 3.6rem;
    }
    .checklist {
      margin: auto;
      display: table;
      @include media-breakpoint-up(sm) {
        column-count: 2;
        display: block;
      }
    }
    .button-group {
      margin: 2.4rem 0 0;
      justify-content: center;
      @include media-breakpoint-up($bpUp) {
        justify-content: flex-start;
      }
    }
    &:before {
      content: "";
      position: absolute;
      width: 14rem;
      left: calc(-1 * var(--margin-inline, 2.3rem));
      transform: translateX(calc(-100% + 4rem));
      height: 100%;
      background: var(--theme-gradient);
    }
  }
  &-image {
    &-wrapper {
      overflow: hidden;
      position: relative;
    }
    &-text {
      padding: 2rem;
      color: var(--white);
      font-size: 1.6rem;
      font-weight: 500;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 80%;
      z-index: 1;
      span {
        display: block;
      }
      @include media-breakpoint-up(sm) {
        width: 60%;
      }
      @include media-breakpoint-up($bpUp) {
        padding: 4rem 5rem;
      }
      &:after {
        content: "";
        z-index: -1;
        @include cover;
        opacity: 0.8;
        background: var(--section-theme-gradient);
      }
    }
  }
}

body[data-style-theme="corporate"] .usp-section .usp-section,
body[data-style-theme="school-wave"] .usp-section .usp-section,
body[data-style-theme="school-circle"] .usp-section .usp-section {
  &-content:before {
    border-radius: 0 8rem 8rem 0;
  }
}

body[data-style-theme="corporate"] .usp-section .usp-section,
body[data-style-theme="school-circle"] .usp-section .usp-section {
  &-image-text {
    padding: 4rem 6rem 2rem 2rem;
    @include media-breakpoint-up($bpUp) {
      padding: 5rem 6rem 4rem 4rem;
    }
    &:after {
      clip-path: url(#clip_header_type_1_circle);
      width: calc(100% + 5rem);
      @include media-breakpoint-up($bpUp) {
        width: calc(100% + 5rem);
      }
    }
  }
}

body[data-style-theme="school-straight"] .usp-section .usp-section {
  &-image-text {
    span {
      width: 80%;
      @include media-breakpoint-up($bpUp) {
        width: 100%;
      }
    }
    &:after {
      clip-path: polygon(0 0, 80% 0%, 100% 100%, 0% 100%);
    }
  }
}

body[data-style-theme="school-wave"] .usp-section .usp-section {
  &-image-text {
    width: 100%;
    padding: 9rem 2rem 2rem;
    @include media-breakpoint-up($bpUp) {
      padding: 8rem 4rem 3rem;
    }
    span {
      width: 50%;
    }
    &:after {
      clip-path: url(#clip_header_type_1_wave);
    }
  }
}

[data-style-theme="corporate"] .usp-section-image-wrapper,
[data-style-theme="school-circle"] .usp-section-image-wrapper,
[data-style-theme="school-wave"] .usp-section-image-wrapper {
  border-radius: var(--theme-border-radius, 3rem);
}
