.download-section {
  display: grid;
  --section-background-color: var(--section-theme-gradient);
  &-inner {
    @include containerGrid;
    &-background {
      grid-column: column-one / column-twelve;
      grid-row: 1;
      border-radius: 3rem;
      position: relative;
      @include media-breakpoint-up($bpUp) {
        grid-column: offset-left / offset-right;
        border-radius: 0;
      }
      .clip-path {
        display: none;
        @include media-breakpoint-up($bpUp) {
          display: block;
        }
      }
    }
    &-content {
      grid-area: section;
      grid-column: column-one / column-twelve;
      grid-row: 1;
      padding: 3rem;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      column-gap: 1.5rem;
      position: relative;
      @include media-breakpoint-up($bpUp) {
        padding: 3rem 0;
      }
    }
  }
  &-downloads {
    grid-column: 1/13;
    order: 2;
    @include media-breakpoint-up($bpUp) {
      grid-column: 1/7;
      order: 1;
    }
    &-item {
      border-bottom: solid 1px var(--white);
      padding: 1.5rem 0;
      a {
        display: flex;
        justify-content: space-between;
        width: 100%;
        text-decoration: none;
        color: var(--white);
        align-items: flex-end;
      }
      svg {
        width: 2.4rem;
        height: 3.1rem;
      }
    }
  }
  &-content {
    grid-column: 1/13;
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 1;
    margin: 0 0 3rem;
    @include media-breakpoint-up($bpUp) {
      grid-column: 8/12;
      order: 2;
      margin: 0 0 1.2rem; // account for the mechano margin;

    }
    .mechano {
      width: 11.5rem;
      height: 2.3rem;
      position: relative;
      display: flex;
      margin: 1rem 0;
      .mechano-1,
      .mechano-2 {
        fill: white;
      }
      .mechano-2 {
        opacity: 0.5;
        transform: translateX(-33%);
      }
    }
    &-title {
      margin: 0 0 8px;
      @extend h1;
    }
    .button-group {
      margin-block-start: var(--vertical-flow);
    }
  }
}

body[data-style-theme="corporate"] {
  .download-section-inner-background {
    grid-column: column-one / column-twelve;
    border-radius: 3rem;
  }
  .download-section-inner-content {
    padding: 3rem 4rem;
  }
}

body[data-style-theme="school-circle"],
body[data-style-theme="school-wave"],
body[data-style-theme="school-straight"] {
  .download-section-inner {
    &-background {
      // grid-column: offset-left / offset-right;
    }
  }
}
