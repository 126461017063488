.timeline-section-wrapper {
  display: grid;
  grid-template-areas: "timeline" "content";
  @include media-breakpoint-up(lg) {
    grid-template-areas: "timeline content content";
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    overflow: hidden;
    border-radius: 3rem;
    background-color: var(--asg-lichtgrijs);
  }
  .timeline {
    &-title {
      @extend h2;
      margin: 0 0 7rem;
    }
    &-wrapper {
      padding: 3rem 5rem;
      grid-area: timeline;
      color: var(--white);
      position: relative;
      background: var(--section-theme-gradient);
      @include media-breakpoint-up(lg) {
        background: transparent;
        padding: 3rem 6rem 9rem 4.5rem;
      }
      &:after {
        content: "";
        background: var(--section-theme-gradient);
        z-index: 0;
        position: absolute;
        left: 0;
        top: 1px;
        aspect-ratio: 62 / 5;
        transform: translateY(-100%);
        @include media-breakpoint-up(lg) {
          height: 100%;
          aspect-ratio: unset;
          transform: translateY(0%);
        }
      }
    }
    &-content {
      padding: 5rem;
      grid-area: content;
      background-color: var(--asg-lichtgrijs);
      border-radius: 0 0 3rem 3rem;
      @include media-breakpoint-up(lg) {
        padding: 8.2rem 8rem;
      }
      &-title {
        @extend h2;
        margin: 0 0 4rem;
        text-align: center;
      }
      .button-group {
        margin: 3rem 0 0;
      }
    }
    &-title,
    &-item {
      position: relative;
      z-index: 1;
    }
    &-items {
      position: relative;
      padding: 0 0 0 6rem;
      z-index: 1;
    }
    &-item {
      margin: 0 0 5rem;
      &:not(:last-of-type) {
        &::after {
          width: 5px;
          height: calc(100% + 3.5rem);
          background-color: var(--white);
          left: -5rem;
          top: 2.4rem;
          position: absolute;
          content: "";
        }
      }

      &-title {
        position: relative;
        font-size: 1.8rem;
        font-weight: 500;
        margin: 0 0 0.95rem;
        &:before {
          content: "";
          @include box(2.4rem);
          background-color: var(--white);
          transform: translate(calc(-50% + 0.25rem), 0.5rem);
          position: absolute;
          left: -5rem;
          top: 0;
          border-radius: 50%;
        }
      }
      &-text {
        font-size: 1.4rem;
      }
    }
  }
}

body[data-style-theme="corporate"],
body[data-style-theme="school-circle"] {
  .timeline-section-wrapper {
    .timeline-title {
      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }
    .timeline-wrapper:after {
      clip-path: url(#section_rounded_top);
      width: 100%;
      @include media-breakpoint-up(lg) {
        clip-path: url(#timeline-clippath-circle);
        width: calc(100% + 6rem);
      }
    }
    .timeline-item:last-of-type {
      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }
  }
}

body[data-style-theme="school-straight"] {
  .timeline-section-wrapper .timeline-wrapper:after {
    clip-path: polygon(calc(100% - 10rem) 0%, 100% 10rem, 100% 100%, 0 100%, 0 0);
    width: 100%;
    @include media-breakpoint-up(lg) {
      clip-path: polygon(calc(100% - 15rem) 0%, 100% 15rem, 100% 100%, 0 100%, 0 0);
    }
  }
}

body[data-style-theme="school-wave"] {
  .timeline-section-wrapper .timeline-wrapper {
    margin: 5rem 0 0;
    @include media-breakpoint-up(lg) {
      margin: 0;
    }
    &:after {
      clip-path: url(#section_wave_top);
      width: 100%;
      @include media-breakpoint-up(lg) {
        clip-path: url(#timeline-clippath-wave);
        width: calc(100% + 5rem);
      }
    }
  }
}
