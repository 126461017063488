.nav {
  position: fixed;
  width: 100%;

  padding-block: 2rem;
  @include z-index(navigation);

  &-inner {
    height: 100%;
    @include flex-cb;
  }
  &::after {
    @include cover;
    content: "";
    background: var(--theme-gradient);
    opacity: 0;
    @include transition(opacity);
    z-index: -1;
  }

  &-brand {
    padding: 0 0 0 3rem;
    z-index: 99;
    @include media-breakpoint-up($bpUp) {
      padding: 0 0 0 3.7rem;
    }

    a,
    img {
      height: inherit;
    }
    img {
      object-fit: contain;
      width: var(--site-logo-width-mobile, var(--site-logo-width-desktop, 12rem));
      max-height: var(--site-logo-height-mobile, var(--site-logo-height-desktop, 6.5rem));
      @include media-breakpoint-up($bpUpNav) {
        width: var(--site-logo-width-desktop, 12rem);
        max-height: var(--site-logo-height-desktop, 6.5rem);
      }
    }
    a {
      display: flex;
      align-items: center;
    }
  }

  &--sticky::after {
    opacity: 1;
  }
  body:not(:has(header)) & {
    &::after {
      opacity: 1;
    }
  }

  &-content {
    @include media-breakpoint-down($bpDownNav) {
      display: grid;
      transition: transform 0.3s ease-in-out;
      @include cover;
      transform: translate(0, -100%);
      padding: 15rem 0 5rem;
      height: 100vh;
      &--active {
        transform: translate(0, 0%);
        .nav-content-background {
          opacity: 1;
          @include transition(opacity);
        }
      }
    }
    @include media-breakpoint-up($bpUpNav) {
      margin: auto 3.7rem auto 0;
      .menu {
        @include flex-ce;
      }
    }
    &-background {
      opacity: 0;
      z-index: -1;
      @include cover;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: calc(100% + 15rem);
        bottom: 0;
        background: var(--theme-gradient);
        z-index: 1;
        opacity: 0.8;
      }

      @include media-breakpoint-up($bpUpNav) {
        display: none;
      }
      &-image {
        @include cover;
        z-index: 0;
        img {
          @include box(100%);
          object-fit: cover;
        }
      }
    }
  }

  &-toggler {
    margin: auto var(--margin-inline, 3rem) auto 0;
    position: relative;
    cursor: pointer;
    @include flex-c;
    color: var(--white);
    gap: 1rem;
    @include media-breakpoint-up($bpUpNav) {
      display: none;
    }
    svg {
      @include box(1.5rem);
      @include transition(transform);
      margin: 0.2rem 0 0;
    }
    &--active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

body[data-style-theme="school-straight"],
body[data-style-theme="school-wave"],
body[data-style-theme="school-circle"] {
  .nav-brand {
    position: relative;
    &:after {
      background-color: var(--white);
      content: "";
      position: absolute;
      top: -2rem;
      bottom: -3rem;
      right: -6rem;
      left: 0;
      z-index: -1;
    }
  }
}

body[data-style-theme="school-straight"] .nav-brand::after {
  clip-path: polygon(100% 0, 100% calc(100% - 3rem), calc(100% - 3rem) 100%, 0 100%, 0 0);
}

body[data-style-theme="school-wave"] .nav-brand::after {
  clip-path: url(#brand-clippath-wave);
  bottom: -4rem;
  right: -6rem;
}

body[data-style-theme="school-circle"] .nav-brand::after {
  clip-path: url(#brand-clippath-circle);
  right: -8rem;
  @include media-breakpoint-up(xl) {
    right: -6rem;
  }
  @media screen and (min-width: 1350px) {
    right: -8rem;
  }
}

body:not(:has(header)) {
  main {
    padding-block: 10.5rem 0;
  }
}
