.video {
	padding-bottom: 56%;
	position: relative;
	width: 100%;
	overflow: hidden;

	&,
	video {
		background-color: black;
	}

	&__overlay,
	iframe,
	video {
		@include cover;
	}

	&__overlay {
		@include transition;
		display: flex;
		justify-content: center;
		align-items: center;

		// Effect that is played after pressing play button.
		animation: fadeOut 1s ease-out;
		animation-play-state: paused;

		img,
		picture {
			@include cover-img;
		}
	}

	&__play {
		z-index: 1;
		position: relative;
		background: none;
		border: none;
		cursor: pointer;
		@include transition(transform);

		&:hover {
			transform: scale(120%);
		}

		svg {
			height: 100px;

			path {
				fill: var(--primary-color);
			}
		}
	}

	&--loaded {
		.video__overlay {
			opacity: 0;
			pointer-events: none;
		}

		.video__play {
			transform: scale(0%);
		}
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(2);
	}
}

