.plate--element + .plate--element__contact_form {
  margin-block-start: var(--vertical-flow);
}
:root {
  // all properties for forms are here.
  --error-color: #ff5454;
  --focus-color: var(--asg-groen);
  --accent-color: var(--asg-blauw);
}

::placeholder {
  font-family: var(--font-family-primary);
  font-size: 1.8rem;
  line-height: 1.5;
  opacity: 0.8;
  color: inherit;
}
// Hide small dot containing spam honeypot input;
[class^="call_me_back_by_fax_"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

.contact_form__label {
  margin-block-end: 1.1rem;
  color: inherit;

  .textarea & {
    text-align: center;
  }
  @extend .uppercase;
  .optional-field {
    opacity: 0.2;
    font-style: italic;
  }
}

.contact_form {
  $self: &;

  background: var(--theme-gradient);
  padding: 3.6rem;

  @include media-breakpoint-up($bpUp) {
    padding: 4.6rem 5.3rem 3rem;
  }
  color: white;

  max-width: 74rem;
  margin-inline: auto;

  .contact_form__helper {
    color: inherit;
    margin-block-start: 0.6rem;
  }

  .wysiwyg {
    a {
      color: inherit;
    }
  }

  .selector-input,
  input,
  select,
  textarea {
    transition:
      border 0.2s ease-in-out,
      color 0.2s ease-in-out,
      background 0.2s ease-in-out,
      outline 0.2s ease-in-out;
    width: 100%; // make sure they max out parent width;
    font-family: inherit;
    background: #ffffff;
    border: 1px solid transparent;

    border-radius: 5rem;
    padding: 2.25rem 2.4rem;

    font-size: 1.6rem;
    line-height: 1.7;
    outline: 0px solid transparent;

    &:hover:not(:focus) {
      border-color: var(--asg-groen);
    }
    &:focus {
      border-color: var(--asg-groen);
      outline: 2px solid var(--focus-color);
    }

    &[disabled] {
      background-color: grey;
      border-color: grey;
    }
  }
  textarea {
    padding: 2.4rem;
    resize: vertical;
    border-radius: 2rem;
  }
  &__field {
    display: flex;
    flex-direction: column;

    // Error notification below input;
    &__error {
      color: var(--error-color);
    }

    &--error {
      input,
      textarea,
      select {
        &:invalid {
          border-color: var(--error-color);
        }
      }
    }
  }

  .hide {
    display: none;
  }
}
.contact-form {
  button[type="submit"] {
    position: relative;
    &[disabled] {
      &:hover,
      &:focus,
      &:active {
        pointer-events: none;
      }
      svg {
        display: none;
      }
      filter: grayscale(1);
      &::after {
        content: "";
        width: 1.8rem;
        height: 1.8rem;
        display: flex;
        animation-name: spin;
        animation-duration: 4000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        border: 2px dotted currentColor;
        border-radius: 100%;
      }
    }
  }
}

.grecaptcha-badge {
  display: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[data-style-theme="corporate"],
[data-style-theme="school-circle"],
[data-style-theme="school-wave"] {
  .contact-form {
    border-radius: var(--theme-border-radius, 3rem);
  }
}
