.annual-report-switchers {
  .annual-report {
    display: grid;
    grid-template-areas: "image" "content" "buttons";
    grid-template-columns: 1fr;
    row-gap: 3rem;
    @include media-breakpoint-up($bpUp) {
      grid-template-areas: "image content content" "image buttons buttons";
      grid-template-columns: 1fr 1fr 1fr;
    }
    &-image {
      grid-area: image;
      margin: 0 auto;
      @include media-breakpoint-up(lg) {
        margin: 0 calc(100% / 4);
      }
      img {
        @include media-breakpoint-up($bpUp) {
          width: 100%;
          height: auto;
        }
      }
    }
    &-content {
      grid-area: content;
      text-align: center;
      margin: 0 calc(100% / 12);
      @include media-breakpoint-up($bpUp) {
        margin: auto calc(100% / 9) 0;
      }
      h2 {
        margin: 0 0 2rem;
      }
    }
  }
  .report-switcher-buttons {
    grid-area: buttons;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.6rem;
    justify-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-up($bpUp) {
      align-items: flex-start;
    }
    .selector-wrapper {
      position: relative;
      select {
        -webkit-appearance: none;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        background-color: var(--asg-grijs);
        color: var(--white);
        font-size: 1.6rem;
        border: none;
        font-weight: 600;
        border-radius: 3rem;
        padding: 1.8rem 5rem 1.8rem 2.4rem;
        outline: none;
        background-image: none;
      }
      svg {
        right: 2.4rem;
        top: 2.4rem;
        position: absolute;
        color: white;
        width: 1.5rem;
        height: 0.8rem;
        pointer-events: none;
      }
    }
    .button {
      font-size: 1.6rem;
      font-weight: 700;
      svg {
        width: 1.4rem;
        height: 1.8rem;
      }
    }
  }
}
