.header-vacancy {
  @include z-index(header);
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-areas: "header";
  &-media {
    grid-area: header;
    position: relative;
    z-index: 1;
    picture {
      display: flex;
      width: 100%;
      height: 100%;

      // Dark overlay.
      &::after {
        content: "";
        @include cover;
        background: var(--theme-gradient);
        opacity: 0.5;
        mix-blend-mode: multiply;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-inner {
    grid-area: header;
    position: relative;
    z-index: 3;
    padding-block: 11rem 8rem;
    margin-inline: 1.5rem;
    padding-inline-end: 2rem;
    padding-inline-start: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include media-breakpoint-up(sm) {
      padding-block: 9rem 6rem;
      margin-inline: var(--margin-inline, 5.35rem);
    }
    @include media-breakpoint-up($bpUp) {
      padding-block: 9rem 13rem;
    }
    @include media-breakpoint-up(lg) {
      width: calc(85% - var(--margin-inline));
    }
    .header-title {
      margin: 0 0 2.4rem;
    }
    .header-title,
    .header-text {
      color: var(--white);
      text-align: center;
    }
    .header-cta {
      display: flex;
      color: var(--white);
      margin: 6.4rem 0 0;
      flex-direction: column;
      background-color: var(--first-color);
      padding: 1.2rem 2.5rem;
      border-radius: 5rem;
      display: flex;
      font-size: 2.2rem;
      gap: 2rem;
      justify-content: center;
      text-align: center;
      @include media-breakpoint-up($bpUp) {
        padding: 1.2rem 1.5rem 1.2rem 4rem;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        align-items: center;
      }
      .button {
        margin: 0 auto;
        @include media-breakpoint-up($bpUp) {
          margin: 0;
        }
        span {
          white-space: nowrap;
        }
      }
    }
  }
}
