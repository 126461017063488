.header-no-media {
  .header-no-media-inner {
    grid-template-areas:
      "title"
      "text"
      "mechano";
    justify-content: center;
    align-content: center;
    text-align: center;
    padding-block: 14rem 6rem;
    & > * {
      max-width: 57rem;
    }
    @include media-breakpoint-up(lg) {
      padding-block: 5rem;
      min-height: 50rem;
    }
    .header-title {
      grid-area: title;
    }
    .header-text {
      grid-area: text;
      margin-block-start: 1rem;
      align-content: flex-end;
    }
    .mechano {
      grid-area: mechano;
    }
    .button-group {
      margin: 2rem 0 0;
      justify-content: center;
    }
    .search-button {
      position: absolute;
      left: 0;
      top: 9rem;
    }
  }
  .mechano {
    display: grid;
    grid-template-areas: "mechano";
    justify-content: center;
    .mechano-1 {
      grid-area: mechano;
      display: flex;
      fill: white;
      width: 2.4rem;
      aspect-ratio: 1/3;
      position: relative;
      rotate: 90deg;
      z-index: 2;
    }
  }
}

body[data-style-theme="corporate"] {
  .header-no-media {
    .header-inner {
      min-height: 50rem;
    }
  }
}

body[data-style-theme="school-straight"] {
  .header-no-media {
    .header-no-media-inner {
      margin-top: 5rem;
      &:before {
        content: "";
        background-color: var(--white);
        opacity: 0.1;
        position: absolute;
        top: 5rem;
        pointer-events: none;
        bottom: 0;
        left: -1.5rem;
        right: -1.5rem;
        margin: auto;
        clip-path: url(#clip_header_type_1_straight);
        clip-path: polygon(calc(100% - 10rem) 0%, 100% 10rem, 100% 100%, 0 100%, 0 0);
        @include media-breakpoint-up(sm) {
          clip-path: polygon(calc(100% - 15rem) 0%, 100% 15rem, 100% 100%, 0 100%, 0 0);
          max-width: calc(57rem + 20rem);
          left: 0;
          right: 0;
        }
      }
    }
  }
}

body[data-style-theme="school-wave"],
body[data-style-theme="school-circle"] {
  .header-no-media {
    &:after {
      content: "";
      background-color: var(--white);
      opacity: 0.1;
      position: absolute;
      z-index: 2;
      pointer-events: none;
    }
    .header-no-media-inner {
      margin-top: 5rem;
    }
  }
}

body[data-style-theme="school-wave"] {
  .header-no-media:after {
    width: 100%;
    bottom: 0;
    left: 0;
    pointer-events: none;
    height: calc(100% - 10rem);
    clip-path: url(#clip_header_type_1_wave_mobile);
    @include media-breakpoint-up($bpUp) {
      clip-path: url(#clip_header_type_1_wave);
      height: 50%;
    }
    @include media-breakpoint-up(lg) {
      height: calc(100% - 10rem);
    }
  }
}

body[data-style-theme="school-circle"] {
  .header-no-media:after {
    width: 110%;
    top: 10rem;
    aspect-ratio: 1;
    clip-path: circle(50% at 50% 50%);
    transform: translate(-20%, 25%);
    @include media-breakpoint-up(sm) {
      clip-path: circle(50% at 50% 50%);
      aspect-ratio: 1;
      right: 30%;
      width: 100%;
      transform: translate(0, 0);
    }
    @include media-breakpoint-up($bpUp) {
      width: 80%;
    }
  }
}
