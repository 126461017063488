.autofill {
   position: relative;
   // margin-bottom: 2rem;
   input {
      margin-bottom: 0.5rem;
      width: auto;
      border: none;
      flex: 1 1 auto;
   }

   &__answers {
      margin: -0.25rem !important;

      &__item {
         margin: 0.25rem 0.5rem;
         padding: 0.25rem 0.5rem !important;
         cursor: pointer;
         display: block;
         font-size: 1.2rem;
         background-color: var(--lightgrey);
      }
   }
   &__list {
      list-style: none;
      margin: -0.25rem;
      padding: 0.25rem;
      position: absolute;
      bottom: 0;
      background-color: var(--lightgrey);
      transform: translateY(100%);
      z-index: 1;
      transform: translateY(calc(100%));
      width: 100%;

      opacity: 0;
      pointer-events: none;
      @include transition(opacity);
      &--active,
      &:hover {
         opacity: 1;
         pointer-events: all;
      }
      &__item {
         margin: 0.25rem 0.5rem;
         padding: 0.25rem 0.5rem !important;
         display: block;
         font-size: 1.2rem;
         background-color: var(--white);
         &::before {
            display: none !important;
         }
      }
   }
   &:hover {
      .autofill__list {
         opacity: 1;
         pointer-events: all;
      }
   }
}
