.search-index {
  padding: 5rem 0;
  @include media-breakpoint-up($bpUp) {
    padding: 5rem 0 7.5rem;
  }
  .search-form {
    @include media-breakpoint-up($bpUp) {
      margin: 0 auto;
      max-width: 80%;
    }
    &-wrapper {
      position: relative;
      input {
        border: 1px solid currentColor;
        border-radius: 3rem;
        padding: 1.4rem 2rem;
        width: 100%;
      }
    }
    &-submit {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      padding: 0 2rem;
      @include flex-c;
      input {
        border: none;
        background-color: transparent;
        @include cover;
        opacity: 0;
      }
      svg {
        @include box(3rem);
      }
    }
  }
  &pagination,
  &-results__list {
    margin: 2.5rem 0 0;
  }
}
