.quote {
  display: grid;
  grid-template-areas: "quote" "quotee";
  flex-direction: column;
  gap: 3.6rem;
  &.show-border {
    border-radius: 3rem;
    border: solid 2px var(--copy-color);
    padding: 4.5rem 2.7rem;
    @include media-breakpoint-up($bpUp) {
      padding: 3.6rem 5rem;
      border-radius: 3rem;
      border: solid 2px var(--copy-color);
    }
  }
  .quote-text {
    grid-area: quote;
    max-width: 58ch;
    padding-bottom: 0.6rem; // A bit of padding for 'clip' issues; ASG-100.

    // Just hardcore everything the same.
    &,
    > * {
      text-wrap: balance;
      display: inline;
      font-size: var(--quote-font-size, 2.8rem);
      font-style: normal;
      font-weight: 500;
      line-height: 1; /* 121.429% */
    }
  }

  // footer.
  .quotee {
    grid-area: quotee;
    display: grid;
    grid-template-areas: "mechano text";
    justify-content: flex-start;
    gap: 1.8rem;
  }
  .quote-vertical-svg {
    flex: 0 0 auto;
    align-self: flex-start;

    // fill: var(--text-color, url(#gradient_mechano_vertical));
    width: 2.5rem;
    aspect-ratio: calc(23 / 68);
    background: var(--theme-gradient);
    clip-path: url(#svg_header_vertical_clip);
  }

  @container element (min-width: 55rem) {
    .quote-text {
      --quote-font-size: 3.6rem;
    }
    .quotee {
      grid-template-columns: var(--plate-column-width, 0rem) 1fr;
      gap: 0;
    }
  }
}

// Mobile only.
@media only screen and (max-width: 550px) {
  .quote {
    padding: 4rem;
  }
}

@container element (min-width: 95rem) {
  .quote {
    grid-template-areas: "quote . quotee";
    grid-template-columns: 7fr 1fr 4fr;
  }
}
