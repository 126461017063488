.selector {
  $self: &;
  position: relative;
  z-index: 5;

  &.selector--checkbox {
    .selector-list-item {
      cursor: initial;
    }

    label {
      cursor: pointer;
    }
  }

  [data-disabled="true"] {
    pointer-events: none;
    opacity: 0.5;
  }

  &-wrapper {
    position: relative;
  }

  &-input,
  &-wrapper select {
    align-items: center;
    justify-content: space-between;
    width: 100%;

    appearance: none;
    background: url("data:image/svg+xml,%3Csvg fill='none' height='7' viewBox='0 0 12 7' width='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='m1.57764 1.01367 4.375 4.375 4.37496-4.375' stroke='%235d5a88'/%3E%3Cpath d='m1.57764 1.01367 4.375 4.375 4.37496-4.375' stroke='%2318277a'/%3E%3Cpath d='m1.57764 1.01367 4.375 4.375 4.37496-4.375' stroke='%23004c03' stroke-opacity='.2'/%3E%3C/g%3E%3C/svg%3E"),
      white;
    background-repeat: no-repeat;
    background-position: right 1.6rem center;
    background-size: 1.5rem;

    line-height: calc(18 / 14);
    color: rgb(19, 46, 99);
    font-size: 1.4rem;
    padding: 1.4rem 1.8rem;
    border-radius: 3rem;
    font-family: inherit;
    border: 1px solid currentColor;

    svg {
      margin-left: 2rem;
      height: 1rem;
      width: 1rem;
      pointer-events: none;
      @include transition;
    }
  }

  &-input {
    cursor: pointer;
    display: inline-flex;
  }

  // Set pointer events to none, else you cannot click on the chosen value and placeholder initially;
  &-value,
  &-placeholder {
    pointer-events: none;
  }

  &-value {
    display: none;
  }

  &-list {
    width: 100%;
    @include transition;
    background: rgba(240, 240, 240, 1);
    overflow: hidden;
    &-wrapper {
      grid-template-rows: 1fr;
      position: absolute;
      transition: grid-template-rows 0.3s ease-in-out;
      display: grid;
      border: solid var(--grey) 1px;
      width: 100%;
      border-top: none !important;
      top: calc(100% - 1px);
      @include transition;
    }
    &-item {
      cursor: pointer;
      padding: 1rem;
    }
  }

  &:not(.selector--open) {
    .selector-list-wrapper {
      grid-template-rows: 0fr;
      border-color: transparent;
    }
  }

  &--open {
    z-index: 11;
    .selector-input {
      border-color: var(--accent-color);
      outline: 2px solid var(--focus-color);
    }
    svg {
      transform: rotate(180deg);
    }
  }
  &-wrapper {
    &:has(.selector--open),
    &:focus-within {
      .selector-input {
        border-color: var(--accent-color);
        outline: 2px solid var(--focus-color);
      }
    }
  }

  &--closing {
    z-index: 11;
  }

  &--selected {
    #{$self}-value {
      display: block;
    }

    #{$self}-placeholder {
      display: none;
    }
  }
}
.select.contact_form__field--error {
  .selector__input {
    border: 1px solid var(--error-color);
  }
}

.is_touch_device {
  .selector:not(.selector--checkbox) {
    display: none;
  }

  .selector__wrapper {
    select {
      display: block;
    }
  }
}

[data-style-theme="corporate"],
[data-style-theme="school-circle"],
[data-style-theme="school-wave"] {
  .selector {
    &-input,
    &-wrapper select {
      border-radius: var(--theme-border-radius, 3rem);
    }
  }
}
