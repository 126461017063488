.image-text-section {
  &-wrapper {
    display: grid;
    grid-template-areas: "content" "image";
    grid-template-columns: 1fr;
    gap: 5rem calc(100% / 12);
    margin: 0 2.5rem;
    @include media-breakpoint-up($bpUp) {
      grid-template-areas: "image content";
      grid-template-columns: 1fr 1fr;
    }
    @include media-breakpoint-up(lg) {
      margin: 0 calc(100% / 12);
    }
    .image {
      grid-area: image;
      @include flex-c;
      img {
        width: 100%;
        border-radius: var(--image-border-radius, 3rem);
        overflow: hidden;
      }
    }
    .content {
      grid-area: content;
      @include flex-c;
      text-align: center;
      flex-direction: column;
      @include media-breakpoint-up($bpUp) {
        align-items: flex-start;
        justify-content: center;
        text-align: left;
      }
      &-title {
        margin: 0 0 2rem;
      }
      &-buttons {
        margin: 2rem 0 0;
      }
    }
  }
}
