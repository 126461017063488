// Helps create a grid to help user choose the right span column value.
:root {
    --offset: 0px;
    --max_width: 100%;
    --gutter: var(--form-grid-column-gap, 3rem);
    --columns: 12;
    --color: hsla(223.2, 100%, 61.4%, 0.2); // hsla(80, 100%, 80%, 0.2);

    --repeating-width: calc(100% / var(--columns));
    --column-width: calc((100% / var(--columns)) - var(--gutter));
    --background-width: calc(100% + var(--gutter));
    --background-columns: repeating-linear-gradient(
                    to right,
                    var(--color),
                    var(--color) var(--column-width),
                    transparent var(--column-width),
                    transparent var(--repeating-width)
    );
}

.contact_form {
    position: relative;
    [data-content-editor="true"] &::before {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        margin-right: auto;
        margin-left: auto;
        width: calc(100% - (2 * var(--offset)));
        max-width: var(--max_width);
        //min-height: 100vh;
        content: '';
        //background-image: var(--background-columns);
        background-size: var(--background-width) 100%;
        //z-index: 1000;
        z-index: -1;
        pointer-events: none;
    }
}

.contact_form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: var(--form-grid-column-gap, 3rem);
    row-gap: var(--form-grid-row-gap, 2.4rem);
    & > :not([data-grid-column]) {
        grid-column: span 1;
    }

    // Grid data-column-span data property styling. If not set, use above everything selector.
    @for $i from 1 through 12 {
        [data-grid-column="#{$i}"] {
            grid-column: span 1;
        }
    }
    @include media-breakpoint-up($bpUp) {
        grid-template-columns: repeat(12, 1fr);
        & > :not([data-grid-column]) {
            grid-column: span 12;
        }
        @for $i from 1 through 12 {
            [data-grid-column="#{$i}"] {
                grid-column: span #{$i};
            }
        }
    }
}
