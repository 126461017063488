.header-media {
  @include z-index(header);
  overflow: hidden;
  position: relative;
  display: grid;
  background: var(--theme-gradient);
  grid-template-areas: "header";
  // Corpo style.
  $_type_0_breakpoint: lg;
  @include media-breakpoint-up($_type_0_breakpoint) {
    max-height: 50rem;
    height: 50rem;
  }
  .header-media-inner {
    .header-text {
      margin-block-start: 1rem;
    }
  }
  .header-media-media {
    grid-area: header;
    position: relative;
    z-index: 1;

    // Static background. When waiting for image loading and video and such.
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: var(--theme-gradient);
    }

    video {
      display: flex;
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      &.on-loaded-data {
        opacity: 1;
      }
    }

    picture {
      position: absolute;
      inset: 0;
      display: flex;
      width: 100%;
      height: 100%;

      // Dark overlay.
      &::after {
        content: "";
        display: flex;
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.2);
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  .header-media-inner {
    grid-area: header;
    position: relative;
    z-index: 3;
    padding-block: 9rem 6rem;
    margin-inline-start: var(--margin-inline, 5.35rem);
    width: 80%;
    padding-inline-end: 2rem;
    padding-inline-start: 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up($bpUp) {
      width: 65%;
    }
    @include media-breakpoint-up($_type_0_breakpoint) {
      padding-inline-end: unset;
      padding-inline-start: 5rem;
      width: calc(50% - var(--margin-inline) + 5rem);
      padding-block: 14rem 6rem;
    }
    .mechano {
      transform: translateX(-100%);
      margin-top: 0.2rem;
      position: absolute;
      left: -1.7rem;
      top: 0;
      display: grid;
      justify-content: center;
      @include media-breakpoint-up($_type_0_breakpoint) {
        margin-top: 0.9rem;
        left: -2.4rem;
      }
      .mechano-1,
      .mechano-2 {
        width: 2.4rem;
        fill: white;
        aspect-ratio: 1/3;
      }
      .mechano-1 {
        display: flex;
        width: 2.4rem;
        position: relative;
      }
      .mechano-2 {
        opacity: 0.5;
        transform: translateY(-33%);
      }
    }

    .header-title {
      position: relative;
    }
    .button-group {
      margin: 2rem 0 0;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

body[data-style-theme="corporate"] {
  .header-media {
    .header-media-media {
      &::before {
        height: 60%;
        clip-path: url(#clip_header_type_0_mobile);
        width: 125%;
        top: 0;
        content: "";
        position: absolute;
        z-index: 2;
        background: var(--theme-gradient);
        opacity: 0.85;
        left: -5px;
        @include media-breakpoint-up(lg) {
          height: 100%;
          width: 60%;
          clip-path: url(#clip_header_type_0);
        }
      }
    }
    .header-media-inner {
      justify-content: space-between;
      min-height: 65rem;
      @include media-breakpoint-up(lg) {
        justify-content: center;
        min-height: unset;
      }
    }
  }
}

body[data-style-theme="school-wave"],
body[data-style-theme="school-straight"],
body[data-style-theme="school-circle"] {
  .header-media {
    min-height: 65rem;
    @include media-breakpoint-up(lg) {
      min-height: unset;
    }
    .header-media-inner {
      margin-block-start: auto;
      padding: 0 0 2.5rem;
      margin-inline-start: 4rem;
      justify-content: flex-end;
      @include media-breakpoint-up(sm) {
        padding: 0 0 5rem;
        margin-inline-start: var(--margin-inline, 5.35rem);
      }
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        background: var(--theme-gradient);
        z-index: 1;
        left: calc(-1 * var(--margin-inline, 10rem));
        opacity: 0.75;
        z-index: -1;
      }
    }
  }
}

body[data-style-theme="school-circle"],
body[data-style-theme="school-straight"] {
  .header-media .header-media-inner::before {
    top: -10rem;
    right: -5rem;
    @include media-breakpoint-up($bpUp) {
      top: -15rem;
    }
  }
}

body[data-style-theme="school-circle"] {
  .header-media .header-media-inner::before {
    clip-path: url(#clip_header_type_1_circle);
  }
}

body[data-style-theme="school-straight"] {
  .header-media .header-media-inner {
    @include media-breakpoint-down($bpDown) {
      text-align: center;
    }
    &::before {
      clip-path: url(#clip_header_type_1_straight);
    }
  }
}

body[data-style-theme="school-wave"] {
  .header-media .header-media-inner {
    @include media-breakpoint-down($bpDown) {
      text-align: center;
    }
    &::before {
      width: calc(100% + 20rem);
      clip-path: url(#clip_header_type_1_wave_mobile);
      top: -15rem;
      @include media-breakpoint-up($bpUp) {
        clip-path: url(#clip_header_type_1_wave);
        width: 100vw;
      }
    }
  }
}
