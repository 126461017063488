$radioCheckSize: 2rem;
.radio-check {
    $radioCheck: &;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.8rem;
    padding-block: .4rem;

    .contact_form__label {
        margin-block-end: 0.8rem;
    }
    label {
        color: inherit;
        a {
            color: inherit;
        }
    }

    input {
        accent-color: var(--accent-color);
        outline: none; // Should be different in case of radio/checkbox;

        flex: 0 0 auto;
        align-self: flex-start;

        appearance: none;


        position: relative;
        padding: 0;
        width: 2rem;
        height: 2rem;
        border-radius: .4rem;
        border: 1px solid var(--asg-blauw);
        &[type="radio"] {
            &::before {
                content: '';
                position: absolute;
                inset: 2rem;
                transition: all .2s ease-in-out;
                background-color: var(--accent-color);
                border-radius: 100%;
            }
            border-radius: 2rem;
            &:checked {
                border-color: var(--accent-color);

                &::before {
                    inset: .3rem;
                }

            }
        }
        &[type="checkbox"] {
            &::before {
                content: '';
                position: absolute;
                inset: .1rem;
                transition: all .2s ease-in-out;
                background-size: 0%;
                background-position: center;
                background-repeat: no-repeat;
                // Use this SVG code on https://yoksel.github.io/url-encoder/ and change the fill color to the accent color you want.
                // <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="m4.52 12c-.4 0-.78-.16-1.06-.43l-3.2-3.17c-.34-.34-.34-.89 0-1.24.34-.34.9-.34 1.25 0l3.01 2.98 7.97-7.89c.34-.34.9-.34 1.25 0 .34.34.34.89 0 1.24l-8.16 8.08c-.28.28-.66.43-1.06.43z" fill="#84b62a"/></svg>
                background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="m4.52 12c-.4 0-.78-.16-1.06-.43l-3.2-3.17c-.34-.34-.34-.89 0-1.24.34-.34.9-.34 1.25 0l3.01 2.98 7.97-7.89c.34-.34.9-.34 1.25 0 .34.34.34.89 0 1.24l-8.16 8.08c-.28.28-.66.43-1.06.43z" fill="%2384b62a"/%3E%3C/svg%3E');
            }
            &:checked {
                border-color: var(--accent-color);
                &::before {
                    background-size: 90%;
                }
            }
        }
    }
}
