body,
button,
input,
select,
textarea {
	-webkit-font-smoothing: antialiased;
	font-family: var(--font-family-primary, sans-serif);
	color: var(--copy-color);
	font-size: 1.8rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
}

ul {
	list-style: none;

	&,
	li {
		padding: 0;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--font-secondary);
	margin: 0;
	font-weight: 500;
}


h1,
.h1 {
	/* ASG Headings H1 */
	font-size: 3.6rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1;

	@include media-breakpoint-up($bpUp) {
		font-size: 5.6rem;
	}
}

h2,
.h2 {
	/* ASG Headings H1 */
	font-size: 3.6rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1;
}

h2,
.h2 {
	/* ASG Headings H1 */
	font-size: 3.6rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1;
}

.h3,
h3 {
	font-size: 2.8rem;
	font-style: normal;
	font-weight: 500;
	line-height: calc(34/28); /* 121.429% */
}

.h4,
h4 {
	/* ASG Headings/H4 */
	font-size: 2.2rem;
	font-style: normal;
	font-weight: 500;
	line-height: calc(28/22) /* 127.273% */
}

.display-2 {
	/* ASG Special headings/Display 2 */
	font-size: 6rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1;
}
.display-3 {
	/* ASG Special headings/Display 3 */
	font-size: 4.4rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1;
}

// Utility for text transform;
.uppercase {
	letter-spacing: 0.16rem;
	text-transform: uppercase;
}
