.school-map-section {
  padding: 0;
  height: 100%;

  .section-inner {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    @include media-breakpoint-up($bpUp) {
      grid-template-columns: 5fr 7fr;
    }

    > .content {
      padding-block: 5rem;

      margin-inline-start: var(--margin-inline);
    }
    .media {
      min-height: 30rem;
      position: relative;
      &::before {
        background-color: white;
        clip-path: url(#section_school_map);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        aspect-ratio: 188/509;
        z-index: 1;
      }
      @include media-breakpoint-up($bpUp) {
        &::before {
          content: "";
        }
      }
    }
  }
  #school_map {
    width: 100%;
    height: 100%;
    @include media-breakpoint-down($bpDown) {
      width: 100%;
      aspect-ratio: 100/130;
    }
  }
  .gm-style-iw-t .gm-style-iw {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-radius: 50rem;
    padding-left: 2rem;
    .gm-style-iw-d {
      overflow: visible !important;
    }
  }
}

.map-section {
  .section__inner {
    h2 {
      margin: 0 0 2.4rem;
    }
  }
}

.vue-google-map-wrapper {
  position: relative;
  @include media-breakpoint-down($bpDown) {
    margin-inline: 1.5rem;
  }

  &.is-index {
    position: relative;
    z-index: map-get($z-index, header_overflow);
    margin-block-start: -10rem;
  }
  .map-ui {
    //background-color: lime;
    .filter-groups {
      margin-block-start: 1.5rem;
      @include media-breakpoint-up($bpUp) {
        margin-block-start: 0;
        transform: translateY(-50%);
        justify-content: center;
      }
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 2.4rem;
      row-gap: 1rem;

      .filter-list.is-checkboxes {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 2.3rem;
        row-gap: 1rem;

        .filter-item {
          position: relative;
          display: flex;

          &:nth-child(1) {
            --background: var(--asg-blauw);
          }
          &:nth-child(2) {
            --background: var(--asg-groen);
          }
          &:nth-child(3) {
            --background: var(--asg-paars);
          }

          label {
            color: white;
            font-size: inherit;
            line-height: inherit;
            padding: 1.4rem 1.8rem;
            border-radius: 3rem;
            background-color: var(--background);
            border: 1px solid var(--background);
          }
          > input {
            position: absolute;
            inset: 0;
            opacity: 0;

            &:checked {
              + label {
                border-color: rgb(19, 46, 99);
                background: rgb(19, 46, 99);
                color: white;
              }
            }
          }
        }
      }
    }
  }
}
.vue-google-map {
  width: 100%;
  aspect-ratio: 100/130;
  border-radius: 2rem;
  @include media-breakpoint-up(md) {
    aspect-ratio: 100/50;
  }
}

.map-ui {
  font-family: var(--font-family-primary);
  line-height: 1.125;
  font-size: 1.6rem;
  font-weight: 500;
  select {
    transition:
      border 0.2s ease-in-out,
      color 0.2s ease-in-out,
      background 0.2s ease-in-out,
      outline 0.2s ease-in-out;
    width: 100%; // make sure they max out parent width;
    font-family: inherit;
    background-color: var(--asg-rood);
    color: white;
    border: 1px solid transparent;

    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' height='8' viewBox='0 0 13 8' width='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1.18213 1.18555 5.25 5.25 5.24997-5.25' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 1.6rem center;
    background-size: 1.4rem;

    border-radius: 5rem;
    padding: 1.4rem 3.8rem 1.4rem 1.8rem;

    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    outline: 0px solid transparent;

    &:hover:not(:focus) {
      //border-color: var(--asg-groen);
    }
    &:focus {
      //border-color: var(--asg-groen);
      //outline: 2px solid var(--focus-color);
    }

    &[disabled] {
      background-color: grey;
      border-color: grey;
    }
  }
}
.marker-title {
  &,
  a {
    color: var(--asg-blauw);
    text-decoration: none;
    font-weight: 700;
    font-size: 1.5rem;
  }
}
