.featured-vacancy {
  &-wrapper {
    position: relative;
    display: grid;
    grid-template-areas: "branding" "vacancies";
    padding: 3rem;
    grid-template-columns: 1fr;
    gap: 3rem 6rem;
    @include media-breakpoint-up($bpUp) {
      padding: 4rem;
    }
    @include media-breakpoint-up(lg) {
      grid-template-areas: "branding vacancies";
      grid-template-columns: 1fr 3fr;
    }
    .section-background {
      border-radius: 3.5rem;
      z-index: -2;
    }
    &-title {
      @extend h2;
      margin: 1.6rem 0 0;
    }
  }
  &-branding {
    grid-area: branding;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include media-breakpoint-up($bpUp) {
      flex-direction: column;
    }
    .logo {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }
  &-quote {
    padding: 0;
    @include media-breakpoint-up(lg) {
      padding: 3.6rem 0 0;
    }
    .quote-span {
      @extend h3;
      color: var(--first-color);
      padding: 0 0 1.6rem;
      display: block;
    }
  }
  &-inner {
    grid-area: vacancies;
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
  }
}
