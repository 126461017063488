.address {
    .contact_form__label {
        @extend .visually-hidden;
    }
}
// Fieldset.
.address-input-group {
    padding: 0;
    border: none;
    border-color: red;

    input + label {
        order: -1;
        color: inherit;
        @extend .uppercase;
        margin-block-end: 0.2rem;


        .optional-field {
            color: #7D7D7D; // TODO this is a not a named color, perhaps ask?
        }
    }

    .form-control-group {

        display: flex;
        --_gap: 1rem;
        gap: var(--_gap);
        > .form-control {
            width: calc(100% / 2 + var(--_gap));
            margin-bottom: 0 !important;
            margin-top: 0 !important;
        }
    }
    .form-control {
        input {
            background-color: white;
            &[disabled] {
                //background-color: rgba(128, 128, 128, 0.1);
                filter: brightness(0.9);
            }
            &[data-dirty="completed"] {
                filter: unset;
                background-color: #c5ffdf;
                border-color: rgb(0,153,68);
            }

            &[data-dirty="error"] {
                filter: unset;
                background-color: #fdd;
                border-color: rgb(255, 0, 0);
            }
        }
    }

    .form-control + .form-control-group,
    .form-control-group + .form-control,
    .form-control + .form-control {
        margin-top: 1.6rem;
    }
}
