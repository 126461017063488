.plate--element + .plate--element__button_group,
.plate--element__button_group + .plate--element__button_group {
  margin-block-start: var(--vertical-flow);
}

.button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.6rem;
  @include media-breakpoint-up($bpUp) {
    gap: 2.4rem;
  }

  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: flex-end;
  }
}

.button {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;

  font-family: inherit;
  line-height: calc(2.5 / 2);
  font-size: 2rem;

  appearance: none;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  position: relative;
  &:not(.contact_form__field) {
    &::before {
      content: "";
      display: block;
      position: absolute;
      inset: -2px;
      background-color: transparent;
      transition: background-color 0.3s ease-in-out;
      border-radius: var(--button-border-radius, var(--theme-button-border-radius, 4rem));
    }
  }
  > span {
    position: relative;
    z-index: 1;
  }

  &:hover,
  &:focus,
  &:active {
    //transform: scale(1.02);
    &::before {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  &:focus:not(:hover) {
    outline-offset: 1px;
    outline: 2px dotted var(--copy-color);
  }

  background: var(--button-background-color, transparent);
  color: var(--button-text-color, white);
  border-color: var(--button-border-color, transparent);

  // Override the border-radius if needed. Per site derivative.
  border-radius: var(--button-border-radius, var(--theme-button-border-radius, 4rem));

  // What follows are the main color properties. This is later used per type button.
  // For now fixed;
  --button-background-color: var(--button-theme-main-color);
  --button-border-color: var(--button-theme-main-color);
  --button-text-color: var(--white);

  // Default...
  &.theme-default {
    --button-theme-main-color: var(--custom-button-color, var(--asg-groen));
    --button-text-color: white;
  }

  &.theme-1 {
    --button-theme-main-color: var(--first-color);
    --button-text-color: white;
  }
  &.theme-2 {
    --button-theme-main-color: var(--second-color);
    --button-text-color: white;
  }

  // Hardcoded ones. used in BSO section it seems.
  &.asg-groen {
    --button-theme-main-color: var(--asg-groen);
    --button-text-color: var(--white);
  }
  &.asg-paars {
    --button-theme-main-color: var(--asg-paars);
    --button-text-color: var(--white);
  }

  &.asg-grijs {
    --button-theme-main-color: var(--asg-grijs);
    --button-text-color: var(--white);
  }

  &.white {
    --button-theme-main-color: var(--white);
    --button-text-color: var(--copy-color);
  }
  // Gradient theme, which can be overriden per site derivative using the --button-theme-gradient or inherit the --theme-gradient (which also can be overriden) variable.
  &.theme-gradient {
    --button-text-color: white;
    --button-theme-main-color: var(--button-theme-gradient, var(--theme-gradient, var(--asg-green)));
    border: none;

    &::before {
      inset: 0;
    }
  }
  // Gradient theme, which can be overriden per site derivative using the --button-theme-gradient or inherit the --theme-gradient (which also can be overriden) variable.
  &.theme-outlined {
    --button-theme-main-color: transparent;
    --button-border-color: var(--copy-color);
    --button-text-color: var(--copy-color);
  }
  &.white-outlined {
    --button-theme-main-color: transparent;
    --button-border-color: var(--white);
    --button-text-color: var(--white);
  }

  // Size property.
  &.size-extra-small {
    font-size: 1.6rem;
    padding: 1.4rem 1.8rem;
  }
  &.size-small {
    padding: 1.4rem 1.8rem;
  }
  &.size-default {
    padding: 1.8rem 2.8rem;
  }
  &.size-big {
    padding: 2.4rem 3.6rem;
  }

  // Button can have icons. Are SVG elements.
  &.has-prefix-icon,
  &.has-suffix-icon {
    svg {
      max-height: 2rem;
      max-width: 2rem;
    }
  }
}

body[data-style-theme="school-straight"] {
  --theme-button-border-radius: 0;
}

body[data-style-theme="school-wave"] {
  --theme-button-border-radius: 0 4rem 4rem 0;
}
