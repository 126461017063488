.plate--element + .plate--element__paragraph {
  margin-block-start: var(--vertical-flow);
}
.paragraph {
  &.darkgrey {
    color: var(--darkgrey);
  }
  &.lightgrey {
    color: var(--asg-grijs);
  }
  &.white {
    color: var(--white);
  }
  // Show-mechano creates a grid context. Showing the 'mechano' first and content second.
  &.show-mechano {
    display: grid;
    grid-template-areas: "first second";
    gap: 3.7rem;
    justify-content: flex-start;

    .mechano {
      grid-area: first;
      display: grid;
      grid-template-areas: "mechano";
      .mechano-1,
      .mechano-2 {
        grid-area: mechano;
        display: flex;
        width: 2.4rem;
        aspect-ratio: 1/3;
        position: relative;
        background: var(--theme-gradient);
        clip-path: url(#svg_header_vertical_clip);
      }
      .mechano-1 {
        z-index: 2;
      }
      .mechano-2 {
        z-index: 1;
        margin-top: 4.8rem;
        opacity: 0.5;
      }
    }

    .content {
      grid-area: second;
    }
  }

  ul,
  ol,
  p,
  table {
    color: var(--text-color, inherit);
    &:not(:last-child) {
      margin-bottom: var(--vertical-flow);
    }
  }

  a {
    color: var(--text-color, currentColor);
  }

  ul {
    padding: 0;
    list-style: disc;
    padding-inline-start: 2.5rem;

    li {
      padding-left: 1rem;
    }
    ul {
      padding-left: 2rem;
      margin-bottom: 0;
    }
  }

  ol {
    list-style-position: inside;
    padding: 0;

    li {
      padding-left: 2rem;
      position: relative;
    }

    ol {
      padding-left: 2rem;
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--text-color, inherit);
    text-wrap: balance;
  }

  h1,
  h2 {
    + p,
    + blockquote,
    + ul,
    + ol {
      margin-top: var(--vertical-flow);
    }
  }

  h3,
  h4,
  h5,
  h6 {
    + p,
    + blockquote,
    + ul,
    + ol {
      margin-top: calc(var(--vertical-flow) / 2);
    }
  }
}
