.teacher-section {
  position: relative;
  &-images {
    grid-template-columns: repeat(2, 1fr);
    gap: 2.8rem;
    display: grid;
    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(3, 1fr);
    }
    .teacher {
      border-radius: 3rem;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
  &-content {
    margin: 3rem 0 0;
    text-align: center;
    @include media-breakpoint-up(lg) {
      margin: auto 5rem;
    }
    &-title {
      text-align: center;
      margin: 0 0 2.4rem;
    }
  }
  &::before {
    content: "";
    position: absolute;
    width: 150%;
    background: var(--theme-gradient);
    opacity: 0.25;
    right: 0;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    aspect-ratio: 1;
    @include media-breakpoint-up($bpUp) {
      height: 300%;
      bottom: 50%;
      top: unset;
      left: unset;
      transform: translate(0, 0);
    }
    @include media-breakpoint-up(lg) {
      right: 50%;
      bottom: 0;
    }
  }
}
