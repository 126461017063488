.notification {
    position: relative;

    .message {
        border: 1px solid transparent;
        border-radius: .4rem;
        background-color: transparent;
        height: 0;
        opacity: 0;
        transition: all .1s ease-out;
        transform-style: preserve-3d;
        transform: rotateX(90deg) scaleY(0);

        p {
            padding: 1.2rem 1.6rem;
        }
    }
    &.forgotten {
        .message__forgotten {
            height: auto;
            opacity: 1;
            transform: rotateX(0deg) scaleY(1);
        }
    }
    &.error {
        .message__error {
            height: auto;
            opacity: 1;
            transform: rotateX(0deg) scaleY(1);
        }
    }
    &.success {
        .message__success {
            height: auto;
            opacity: 1;
            transform: rotateX(0deg) scaleY(1);
        }
    }

    // When no notification is shown, the notification is absolutely positioned, so it doesn't affect the layout;
    &:not(.forgotten):not(.error):not(.success) {
        position: absolute;
    }

    .message__success {
        border-color: rgba(68, 208, 124, 1);
        background-color: rgba(236, 253, 243, 1);
    }
    .message__forgotten,
    .message__error {
        border-color: rgba(255, 84, 84, 1);
        background-color: rgba(255, 240, 240, 1);
    }
}
