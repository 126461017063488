// Picture element.
.image {
  display: flex;
  img {
    max-width: 100%;
  }

  &--large {
    width: calc(100% + 2 * #{map-get($padding, "column-mobile")});
    margin-left: -1 * map-get($padding, "column-mobile");
  }
  @include media-breakpoint-up($bpUp) {
    &--large {
      height: 100%;
      width: calc(100% + 2 * #{map-get($padding, "column-desktop")});
      margin-left: -1 * map-get($padding, "column-desktop");

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &--center img {
    margin: 0 auto;
  }
  &--right img {
    margin: 0 0 0 auto;
  }
}

.plate--element__image .image {
  overflow: hidden;
}

@mixin marginImageLarge($pos, $size) {
  $posExtra: ":first-of-type";

  @if $pos == "bottom" {
    $posExtra: ":last-of-type";
  }

  .plate--row#{$posExtra} {
    [data-plate-object-type="element_placeholder"] + .plate--element__image,
    .plate--element__image#{$posExtra} {
      .image--large:not(.image--single) {
        height: calc(100% + #{$size});
        margin-#{$pos}: -$size;
        flex: 1 1 50%;
      }
    }
  }

  .plate--row:only-child {
    .image--single {
      height: calc(100% + #{$size} * 2);
      margin-#{$pos}: -$size;
      flex: 1 1 auto;
    }
  }
}
@include media-breakpoint-up($bpUp) {
  .plate--row {
    .plate--column {
      &:first-of-type,
      &:last-of-type {
        .image {
          &--large {
            @for $i from 1 through 12 {
              &-col-#{$i} {
                width: calc((100vw - (100% / #{$i} * 12)) / 2 + 100%);
              }
            }
          }
        }
      }

      &:first-of-type {
        .image {
          &--large {
            @for $i from 1 through 12 {
              &-col-#{$i} {
                margin-left: calc((100vw - (100% / #{$i} * 12)) / -2 + #{map-get($padding, "column-desktop")});
              }
            }
          }
        }
      }

      &:last-of-type:not(:only-child) {
        .image--large {
          margin-left: -1 * map-get($padding, "column-desktop");
        }
      }

      &:only-child {
        .image {
          &--large {
            margin-left: calc((100vw - (100%)) / -2);
            width: calc(100vw);
          }
        }
      }
    }
  }
}

[data-style-theme="corporate"],
[data-style-theme="school-circle"],
[data-style-theme="school-wave"] {
  .plate--element__image .image {
    border-radius: var(--image-border-radius, 3rem);
  }
}
