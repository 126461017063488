.featured-articles-section {
  .section-title {
    text-align: center;
    margin-block-end: 1rem;
    line-height: 1.2; // more because of the 'clip'.
  }
  .section-subtitle {
    text-align: center;
    margin-block-end: 1rem;
    @include media-breakpoint-up($bpUp) {
      font-size: 2.2rem;
    }
  }

  .button-group {
    margin-block: 3.3rem;
  }

  .section-title,
  .section-subtitle {
    + .featured-section-list {
      margin-block-start: 5rem;
    }
  }
}
.featured-section-list {
  display: grid;
  gap: 1.8rem;

  @include media-breakpoint-up($bpUp) {
    // https://grid.layoutit.com/?id=vzOR8z7
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px 30px;

    .article-card:nth-child(1) {
      grid-area: 1 / 1 / 3 / 2;
    }
    .article-card:nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }
    .article-card:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
    }
  }
}

// Rather complex card.
// If has image, we show a gradient.
// If has no image, we show a solid color based on the site colros.
// Other sites might have other shapes in the background of title...
.article-card {
  position: relative;
  color: white;
  min-height: 18rem;
  overflow: hidden;
  display: grid;
  grid-template-areas: "card";
  &.is-image-card {
    min-height: 30rem;
  }

  .article-card-categories {
    font-size: 1.6rem;
    line-height: calc(18 / 16);
    font-weight: 500;
    letter-spacing: 0.16rem;
    text-transform: uppercase;
    margin-block-end: 0.55rem;
  }

  .teaser-svg {
    position: absolute;
    z-index: 5;
    width: 8.3rem;
    height: 8.3rem;
    inset-inline-end: 0;
    inset-block-start: 0;
    fill: white;
    opacity: 0.5;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--theme-card-background);
  }

  .index-image {
    grid-area: card;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: var(--theme-gradient);
      background-blend-mode: multiply, normal;
      opacity: 0.3;
    }
  }

  .theme-0-card-svg {
    grid-area: card;
    z-index: 1;

    position: absolute;
    bottom: -90px;
    width: 40rem;
    height: 40rem;
    left: 20px;
    @include media-breakpoint-down($bpDown) {
      display: none;
    }
  }
  .article-card-content {
    grid-area: card;
    align-self: flex-end;
    position: relative;
    z-index: 10;
    padding: 3rem;

    @include media-breakpoint-up($bpUp) {
      padding: 4.8rem 5rem;
    }
  }
  &:not(.is-image-card) {
    h2 {
      @extend .h3;
    }
  }
}

[data-style-theme="corporate"] .article-card {
  border-radius: var(--theme-border-radius, 3rem);
}

// Theme overrides;
[data-style-theme="school-straight"] .article-card {
  &.is-image-card {
    .article-card-content {
      margin-inline-end: 3rem;
      padding-inline-end: 2rem;
      @include media-breakpoint-up($bpUp) {
        padding: 4.8rem 7rem 4.8rem 5rem;
        margin-inline-end: 4rem;
      }
      &:after {
        content: "";
        clip-path: polygon(calc(100% - 13rem) 0%, 100% calc(100% - 4rem), 100% 100%, 0 100%, 0 0);
        z-index: -1;
        @include cover;
        opacity: 0.8;
        background: var(--section-theme-gradient);
      }
    }
  }
  &:not(.is-image-card) {
    .article-card-content {
      padding-inline-end: 4rem;
      align-self: flex-end;
      margin-inline: 2rem 3rem;
      @include media-breakpoint-up($bpUp) {
        padding: 1.8rem 7rem 2.8rem 5rem;
        margin-inline-end: 4rem;
      }
      &:after {
        content: "";
        clip-path: polygon(calc(100% - 13rem) 0%, 100% calc(100% - 4rem), 100% 100%, 0 100%, 0 0);
        z-index: -1;
        @include cover;
        opacity: 0.1;
        background: white;
      }
    }
  }
}

[data-style-theme="school-circle"] .article-card {
  border-radius: var(--theme-border-radius, 3rem);
  &.is-image-card {
    .article-card-content {
      margin-inline-end: 3rem;
      padding-inline-end: 2rem;
      @include media-breakpoint-up($bpUp) {
        padding: 4.8rem 7rem 4.8rem 5rem;
        margin-inline-end: 4rem;
      }
      &:after {
        content: "";
        border-radius: 100%;
        z-index: -1;
        opacity: 0.8;
        background: var(--section-theme-gradient);

        position: absolute;
        width: 34.5rem;
        height: 34.5rem;
        left: -2rem;
        bottom: -10rem;

        @include media-breakpoint-up($bpUp) {
          bottom: unset;
          height: 69.5rem;
          width: 69.5rem;
          left: -18rem;
          top: 0;
        }
      }
    }
  }
  &:not(.is-image-card) {
    .article-card-content {
      padding-inline-end: 4rem;
      align-self: flex-end;
      margin-inline: 2rem 3rem;
      @include media-breakpoint-up($bpUp) {
        padding: 1.8rem 7rem 2.8rem 5rem;
        margin-inline-end: 4rem;
      }
      &:after {
        content: "";
        opacity: 0.1;
        background: white;
        position: absolute;
        border-radius: 100%;
        height: 28.7rem;
        width: 28.7rem;
        right: -17rem;
        bottom: -5rem;

        @include media-breakpoint-up($bpUp) {
          bottom: unset;
          height: 79rem;
          width: 79rem;
          right: -25rem;
          top: 2rem;
        }
      }
    }
  }
}
[data-style-theme="school-wave"] .article-card {
  border-radius: var(--theme-border-radius, 3rem);
  &.is-image-card {
    .article-card-content {
      &:after {
        content: "";
        z-index: -1;
        opacity: 0.8;
        background: var(--section-theme-gradient);
        position: absolute;
        inset: 0;
        clip-path: url(#clip_article_card_wave);
      }
    }
  }
  &:not(.is-image-card) {
    .article-card-content {
      align-self: flex-end;
      &:after {
        content: "";
        opacity: 0.1;
        background: white;
        position: absolute;
        inset: 0;
        clip-path: url(#clip_article_card_wave);
      }
    }
  }
}
