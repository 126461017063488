.agenda-item-header {
  &-inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "content" "logo";
    gap: 3rem 1.5rem;
    @include media-breakpoint-up($bpUp) {
      grid-template-areas: "content content logo";
      grid-template-columns: repeat(2, 1fr) 30rem;
    }
    &-content {
      grid-area: content;
      @include media-breakpoint-up($bpUp) {
        max-width: 80%;
      }
      .header-title {
        margin: var(--vertical-flow) 0 0;
        font-size: 4.4rem;
      }
      &-bottom {
        margin: 5rem 0 0;
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
        justify-content: space-between;
        align-items: flex-start;
        &-contact {
          display: flex;
          gap: 2rem;
          .mechano {
            .mechano-1 {
              fill: white;
              height: 2.2rem;
              aspect-ratio: 3/1;
              position: relative;
              z-index: 2;
              margin: 5px 0 0;
            }
          }
          &-text a {
            color: #9795b5;
            text-decoration: none;
          }
        }
      }
    }
  }
  .header-text {
    margin-block-start: var(--vertical-flow);
  }
  .agenda-item-image {
    grid-area: logo;
    border-radius: 3rem;
    background-color: var(--darkgrey);
    padding: 0;
    margin: 0 auto;
    aspect-ratio: 1;
    @include flex-cc;
    max-width: 100%;
    @include media-breakpoint-up($bpUp) {
      margin: auto 0;
    }
    img {
      width: 100%;
      border-radius: inherit;
    }
  }
}

body[data-style-theme="school-straight"],
body[data-style-theme="school-wave"],
body[data-style-theme="school-circle"] {
  .header-school {
    &:after {
      content: "";
      position: absolute;
      background-color: var(--white);
      right: 0;
      bottom: 0;
      z-index: 2;
      opacity: 0.2;
    }
  }
}

body[data-style-theme="school-wave"] {
  .header-school::after {
    width: calc(100% + 1rem);
    height: 50%;
    clip-path: url(#clip_header_type_1_wave_mobile);
    @include media-breakpoint-up($bpUp) {
      clip-path: url(#clip_header_type_1_wave);
      height: 50%;
    }
  }
}

body[data-style-theme="school-straight"] {
  .header-school::after {
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0 100%, 0% 20%);
    width: calc(100% - 4rem);
    height: 50%;
    @include media-breakpoint-up($bpUp) {
      width: 50%;
      height: calc(100% - 13rem);
    }
    @include media-breakpoint-up(lg) {
      width: 40%;
    }
  }
}

body[data-style-theme="school-circle"] {
  .header-school::after {
    clip-path: url(#school-header-clippath);
    aspect-ratio: 39 / 20;
    width: 90%;
    @include media-breakpoint-up(sm) {
      width: 80%;
    }
    @include media-breakpoint-up($bpUp) {
      width: 47%;
    }
  }
}
