.go-to-section {
  &.negative-top-margin,
  &.negative-bottom-margin {
    z-index: 119;
  }
  &.negative-top-margin {
    margin-top: -5rem;
    padding-top: 0;
  }
  &.negative-bottom-margin {
    margin-bottom: -5rem;
    padding-bottom: 0;
  }
  &-wrapper {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 2.7rem;
    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
  .go-to-card {
    background-color: var(--asg-lichtgrijs);
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 5rem;
    padding: 3rem 2.5rem;
    text-decoration: none;
    &-title {
      @extend h3;
      margin: 0 0 2.5rem;
    }
    &-link {
      margin: auto 0 0;
      background-color: var(--white);
      border-radius: 5rem;
      padding: 1.4rem 2.4rem;
      color: var(--copy-color);
      text-align: center;
      font-size: 1.6rem;
      margin: auto auto 0;
    }
  }
}
