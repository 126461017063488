.text-background {
  background-color: var(--asg-lichtgrijs);
  padding: 2.5rem;
  border-radius: 3rem;
  height: 100%;
  &-title {
    margin: 0 0 2.3rem;
    text-align: center;
  }
  .button-group {
    margin: 2.3rem 0 0;
    justify-content: center;
  }
  &.center {
    .text-background-text {
      text-align: center;
    }
  }
  &.left {
    .text-background-title {
      text-align: left;
    }
    .button-group {
      justify-content: flex-start;
    }
  }
}

.plate--element__text_background {
  container-type: inline-size;
  container-name: textblock;
  height: 100%;
}

@container textblock (min-width: 450px) {
  .text-background {
    padding: 3.5rem;
  }
}

@container textblock (min-width: 550px) {
  .text-background {
    padding: 5.8rem 8.5rem;
  }
}

@container textblock (min-width: 850px) {
  .text-background {
    padding: 8rem 20rem;
  }
}
