.bso-section {
  .section-background-image {
    @include cover;
    &::before {
      content: "";
      @include cover;
      background: var(--section-theme-gradient);
      opacity: 0.8;
    }
    img {
      @include box(100%);
      object-fit: cover;
    }
  }
  &-title {
    text-align: center;
    @extend h1;
    color: var(--white);
  }
  .bso {
    display: grid;
    color: var(--white);
    grid-template-columns: 1fr;
    padding: 2.5rem 0 2.5rem 2.5rem;
    gap: 3rem 7.5rem;
    align-items: center;
    @include media-breakpoint-up(lg) {
      padding: 5rem 0 5rem 5rem;
      grid-template-columns: 1fr 1fr;
    }
    &-image {
      border-radius: 3rem;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    &-content {
      &-title {
        @extend h1;
        color: var(--white);
        margin: 0 0 1rem;
      }
      .button-group {
        margin: 3rem 0 0;
      }
    }
  }
}
