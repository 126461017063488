.plate--element__directly_to_element {
  container-type: inline-size;
  container-name: directlyto;
}

.directly-to {
  &-cards {
    display: grid;
    gap: 1.7rem;
    justify-content: center;
    grid-template-columns: 1fr;
    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
    }
  }
  &-card {
    color: var(--white);
    text-align: center;
    text-decoration: none;
    padding: 3.5rem 4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: var(--section-theme-gradient);
    width: 26rem;
    margin: 0 auto;
    @include media-breakpoint-up(sm) {
      margin: 0;
    }
    &-subtitle {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.6rem;
    }
    &-title {
      @extend h3;
      color: var(--white);
    }
  }
}

@container directlyto (min-width: 545px) {
  .directly-to-cards {
    .directly-to-card {
      width: 100%;
    }
    &.even-amount,
    &:not(.even-amount) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@container directlyto (min-width: 700px) {
  .directly-to-cards {
    &.even-amount {
      grid-template-columns: repeat(2, 1fr);
    }
    &:not(.even-amount) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

[data-style-theme="corporate"] .directly-to-card,
[data-style-theme="school-circle"] .directly-to-card,
[data-style-theme="school-wave"] .directly-to-card {
  border-radius: var(--theme-border-radius, 3rem);
}
