* {
  box-sizing: border-box;
  margin: 0;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

.plate--section,
#main {
  scroll-margin-top: 20rem;
}

picture {
  &,
  img {
    display: block;
  }
  source {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.plate--element {
  container-name: element;
  container-type: inline-size;
}
