.plate--element + .plate--element__call_to_action_element {
  margin-block-start: calc(var(--vertical-flow) * 2);
}
.call-to-action {
  background: linear-gradient(76deg, #1065af -2.7%, #662783 104.58%, #1c8c37 129.38%);
  background: var(--theme-cta-gradient, var(--theme-gradient));
  display: grid;
  grid-template-areas: "cta";
  border-radius: var(--theme-border-radius);

  .background {
    grid-area: cta;
    display: flex;
    width: 100%;
    height: 100%;
  }

  // picture elemnet.
  .cta-image {
    grid-area: image;
    display: flex;
    width: 100%;
    align-self: flex-start;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: calc(var(--theme-border-radius) / 3 * 2);
    }
  }

  .content {
    grid-area: cta;
    z-index: 2;
    display: grid;
    color: white;
    padding: 3rem;
    @include media-breakpoint-up($bpUp) {
      padding: 5rem;
    }

    grid-template-areas: "content";
    &.has-image {
      grid-template-areas: "image" "content";
      gap: 3rem;

      @include media-breakpoint-up($bpUp) {
        gap: 0;
        grid-template-areas: "image . content";
        grid-template-columns: 5fr 1fr 6fr;
      }
    }
  }

  .content-inner {
    grid-area: content;

    display: flex;
    flex-direction: column;
    gap: var(--vertical-flow);
  }
}

[data-style-theme="corporate"] .article-card,
[data-style-theme="school-circle"] .article-card,
[data-style-theme="school-wave"] .article-card {
  border-radius: var(--theme-border-radius, 3rem);
}
