.faq-section {
  &-content {
    margin-right: 5rem;
    text-align: center;
    @include media-breakpoint-up($bpUp) {
      margin-right: 5rem;
      text-align: left;
    }
    &-description {
      margin: 0 0 2rem;
    }
  }
  .mechano {
    margin: 2rem auto 1rem;
    height: 2.3rem;
    width: 11.5rem;
    display: flex;
    @include media-breakpoint-up($bpUp) {
      margin: 2rem 0;
    }
    .mechano-1,
    .mechano-2 {
      background: var(--theme-gradient);
      clip-path: url(#svg_header_horizontal_clip);
      aspect-ratio: 3/1;
    }
    .mechano-2 {
      transform: translateX(-33%);
      opacity: 0.5;
      z-index: -1;
    }
  }
}
