.video-impression {
  overflow: hidden;
  display: grid;
  grid-template-areas: "video" "content";
  grid-template-rows: 18rem auto;
  position: relative;
  @include media-breakpoint-up($bpUp) {
    grid-template-rows: auto;
    grid-template-areas: "video content";
    grid-template-columns: 5fr 7fr;
  }
  &-wrapper {
    position: relative;
    .label {
      position: absolute;
      right: 2.5rem;
      top: 0;
      transform: translateY(-50%);
      background-color: var(--first-color, var(--asg-blauw));
      color: var(--white);
      padding: 1.4rem 2.4rem;
      border-radius: 5rem;
      @include flex-c;
      gap: 1rem;
      z-index: 3;
      font-size: 1.6rem;
      @include media-breakpoint-up($bpUp) {
        right: 3.7rem;
      }
    }
  }
  .video-impression-image {
    grid-column: video-start / content-end;
    grid-row: video-start / content-end;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .video-link {
    grid-area: video;
    align-self: flex-end;
    justify-self: center;
    position: relative;
    z-index: 3;
    @include media-breakpoint-up($bpUp) {
      align-self: center;
    }
  }
  .content {
    grid-area: content;
    position: relative;
    z-index: 3;
    color: white;
    align-self: flex-end;

    display: flex;
    flex-direction: column;
    gap: 2rem;

    padding: 5rem 3rem;
    @include media-breakpoint-up($bpUp) {
      padding: 4rem 2.5rem 4rem 1.5rem;
    }

    .button-group {
      margin-block-start: 2.4rem;
    }
  }

  background: var(--first-color);
  position: relative;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 2;
    background: var(--theme-gradient);
    mask-image: linear-gradient(90deg, transparent 0%, black 75%);
    opacity: 0.3;
  }
  .video-iframe:not(.fslightbox-source) {
    @include visually-hidden;
  }
}

[data-style-theme="corporate"],
[data-style-theme="school-circle"],
[data-style-theme="school-wave"] {
  .video-impression {
    border-radius: var(--theme-border-radius, 2rem);
  }
}
