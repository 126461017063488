.header {
  grid-template-areas: "header";
  display: grid;
  overflow: hidden;
  position: relative;
  @include z-index(header);
  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    inset: 0;
    background: var(--theme-gradient);
  }
  &-inner {
    margin-inline: 4rem;
    color: white;
    grid-area: header;
    position: relative;
    z-index: 4;
    padding-block: 14rem 6.7rem;
    display: grid;
    @include media-breakpoint-up(sm) {
      margin-inline: var(--margin-inline, 3rem);
    }
  }
  &--overlay {
    z-index: 1;
    margin: 0 0 -10rem;
    padding: 0;
    .header-media-inner {
      padding: 0 0 10rem !important;
    }
  }
}

body[data-style-theme="corporate"] {
  .header {
    .mechano-background {
      fill: white;
      opacity: 0.1;
      grid-area: header;
      position: absolute;
      z-index: 3;
      top: 150%;
      left: 50%;
      transform: translate(-21%, -58%);
      width: 111rem;
      aspect-ratio: 1/1;
    }
  }
}
