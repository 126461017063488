.contact-person {
  text-align: center;
  &-image {
    margin: 0 auto 2rem;
    justify-content: center;
    img {
      border-radius: 3rem;
      overflow: hidden;
    }
  }
  &-content-title {
    @extend h2;
    margin: 0 0 1.4rem;
  }
}
