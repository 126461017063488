.map-numbers {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-block: 5rem 8rem;
  gap: 3rem;

  .number-element {
    flex: 1 1 40%;
  }
}
.number-element {
  display: flex;
  gap: 3.6rem;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  &-number {
    font-size: 17.5rem;
    font-weight: 500;
    line-height: 1;
    span {
      font-size: 8rem;
    }
  }
  &-content {
    padding: 0 2.5rem;
    &-title {
      font-weight: 500;
      font-size: 2.2rem;
    }
  }
  &.asg-blauw {
    .number-element-number {
      color: var(--first-color, var(--asg-blauw));
    }
  }
  &.asg-groen {
    .number-element-number {
      color: var(--second-color, var(--asg-groen));
    }
  }
  &.asg-paars {
    .number-element-number {
      color: var(--first-color, var(--asg-paars));
    }
  }
}

@container element (min-width: 768px) {
  .number-element {
    flex-direction: row;
    text-align: left;
    &-content {
      padding: 0;
    }
  }
}
