.branding-section {
  background: var(--theme-gradient);
  color: var(--white);
  &-wrapper {
    display: grid;
    grid-template-areas: "content" "branding";
    grid-template-columns: minmax(0, 1fr);
    gap: 2.4rem 2.8rem;
    @include media-breakpoint-up($bpUp) {
      grid-template-areas: "content content content content content content content content branding branding branding branding";
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
  &-content {
    grid-area: content;
    .title {
      margin: 0 0 2.4rem;
      @extend h1;
    }
    .button-group {
      margin: 2rem 0 0;
    }
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
  }
  &-branding {
    grid-area: branding;
    .logo img {
      margin: 0 auto;
    }
    .website {
      margin: 3rem 0 0;
      text-align: center;
      font-size: 2.8rem;
      font-weight: 500;
      color: var(--white);
      display: block;
      text-decoration: none;
    }
    img {
      filter: grayscale(1) brightness(5);
    }
  }
}
