.share-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-block: 2rem;
    gap: 1.5rem;
}

.share-links-title {
    width: 100%;
    text-align: center;
    margin-block-end: 2rem;
}

// a element
.share-link-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.4rem;
    color: var(--copy-color);
    text-decoration: none;

    svg {
        flex: 0 0 auto;
        width: 3rem;
        height: 3rem;
        fill: url(#gradient_share_link);
    }
    span {
        color: inherit;
        font-size: 1.1rem;
        line-height: calc(1.3/1.1);
        text-align: center;
        text-wrap: balance;
        max-width: 10ch;
        transition: opacity 0.2s ease;
        opacity: 0;
        display: none;

        @extend .text-clip;

    };
}

@media (hover: hover) {
    /* when hover is supported */
    .share-link-item {
        span {
            display: block;
        }
        &:hover {
            span {
                opacity: 1;
            }
        }
    }
}