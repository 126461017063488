.animateSection {
   section {
      height: 90vh;
      display: flex;
      justify-content: space-around;
      align-items: center;
   }

   .animate,
   .observeAnimate {
      display: inline-block;

      .box {
         margin: 0 20px;
         height: 70px;
         width: 70px;
         background: #888;
      }
   }

   .big {
      transform: scale(3);
   }
}
