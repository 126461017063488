.logo-swiper-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    min-width: 0;
    width: 100%;
    overflow: hidden;
    //put here the mask-image
    .logo-swiper {
        width: 100%;
        overflow: visible;
        flex: 1 1 auto;

        --mask-offset: 3rem;
        mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) calc(0% + var(--mask-offset)), rgba(0,0,0,1) calc(100% - var(--mask-offset)), rgba(0,0,0,0) 100%);
    }

    .swiper-wrapper {
        -webkit-transition-timing-function: linear !important;
        -o-transition-timing-function: linear !important;
        transition-timing-function: linear !important;
    }

    .button-group {
        flex: 0 0 auto;
        align-self: flex-end;
        @include media-breakpoint-down($bpDown) {
            align-self: center;
        }
    }
    @include media-breakpoint-down($bpDown) {
        padding-block: 3rem;
    }
}
@container element (min-width: 565px) {
    .logo-swiper-wrapper {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        .logo-swiper {
            width: 50%;
        }
        .button-group {
            align-self: center;
        }
    }
}
.logo-swiper {
    //overflow: hidden;
    .swiper-slide {
        height: auto;
    }
}
.logo-slide {
    display: flex;
    align-items: center;
    height: 100%;

    img {
        width: 100%;
        max-height: 12rem;
        object-fit: contain;
    }
}
