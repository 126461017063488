.contact-section {
  background: var(--section-background-color);
  &.section-background-gradient {
    .contact-section-quote-text {
      color: var(--white);
    }
    .contact-section-branding img {
      filter: grayscale(1) brightness(5);
    }
  }
  &-quote {
    position: relative;
    background-color: var(--asg-lichtgrijs);
    border-radius: 2.5rem;
    align-items: center;
    overflow: hidden;
    display: grid;
    grid-template-areas: "quote image";
    grid-template-columns: 5fr 3fr;
    &-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 calc(100% / 12) 2rem 0;
    }
    &-title {
      grid-area: quote;
      padding: 5rem;
      color: var(--first-color);
    }
    &-text {
      font-size: 2rem;
      margin: 0 calc(100% / 12) 0 0;
      @include media-breakpoint-up($bpUp) {
        font-size: 2.2rem;
      }
    }
    &-image {
      // flex: 0 0 25%;
      height: 100%;
      grid-area: image;
      overflow: hidden;
      // aspect-ratio: 1;
      position: relative;
      margin-left: -5rem;
      img {
        position: absolute;
        top: -5rem;
        bottom: -5rem;
        border-radius: 50%;
        right: -2.5rem;
        height: calc(100% + 10rem);
        object-fit: cover;
      }
    }
    &-contact {
      background-color: var(--asg-groen);
      padding: 2rem 2.4rem;
      border-radius: 2.5rem;
      color: var(--white);
      position: absolute;
      margin: auto 0;
      right: 0;
      transform: translateX(50%);
      display: flex;
      gap: 0.8rem;
      align-items: flex-start;
      .contact-icon {
        margin: 0.5rem 0 0;
        svg {
          @include box(2rem);
        }
      }
      a {
        display: block;
        color: var(--white);
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &-branding {
    display: flex;
    gap: 5rem;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0 0;
  }
}
