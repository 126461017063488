.plate--element__accordion + .plate--element__accordion {
  margin-top: 8rem;
}

.accordion {
  .accordion-title {
    margin-block-end: 4rem;
  }
}

// detail element with class: .details-and-summary
.details-and-summary {
  background-color: var(--white);
  border-radius: 3rem;
  margin: 0 0 1.4rem;
  &-icon {
    flex: 0 0 auto;
    @include box(5rem);
    border-radius: 50%;
    background: var(--asg-lichtgrijs);
    transition:
      transform 0.2s ease,
      background 0.2s ease-in-out,
      color 0.2s ease-in-out;
    @include flex-cc;
  }
  summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0 solid transparent;
    outline: none;
    gap: 1rem;
    padding: 1.5rem 2rem;
    position: relative;
    cursor: pointer;
    @extend h4;
    color: var(--second-color);
    @include media-breakpoint-up($bpUp) {
      padding: 1.5rem 4rem;
    }
    &:focus {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }

  summary::-webkit-details-marker {
    display: none;
  }

  &[open] {
    .details-and-summary-icon {
      background: var(--theme-gradient, var(--asg-paars));
      transform: rotate(45deg);
      color: var(--white);
    }
  }

  .content {
    padding: 0 2rem 2rem;
    border: 0 solid transparent;
    border-block-start: none;
    @include media-breakpoint-up($bpUp) {
      padding: 0 4rem 3rem;
    }
  }
}
