.language-picker {
    border: 1px solid crimson;
    position: relative;
    font-size: 80%;
    font-family: inherit;
    z-index: 1; // Just make sure it's on top of the SVG gimmick in top-menu themes.
    &.active {
        .language-picker-menu {
            display: flex;
        }
    }
}
.current-language-button {
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: 1rem;

    cursor: pointer;
    background: none;
    border: none;
    appearance: none;
    border-radius: .5rem;
    background: white;
    // flag.
    svg {
        width: 2rem;
        aspect-ratio: 3/2;
    }
}

.language-picker-menu {
    width: 100%;
    padding: 1rem;
    margin-block: .5rem;
    border-radius: .5rem;
    background: white;
    display: none;

    flex-direction: column;
    gap: 1rem;

    @include media-breakpoint-up($bpUpNav) {
        position: absolute;
    }

    a {
        color: black;
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}