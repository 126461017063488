.cards-element {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
}
