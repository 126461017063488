.related-section {
  background: var(--section-background-color);
  padding: 5rem 0 7.5rem;
  &-title {
    color: var(--white);
    margin: 0 0 4rem;
    text-align: center;
    @include media-breakpoint-up($bpUp) {
      text-align: left;
    }
  }
  &-vacancies {
    display: grid;
    gap: 2.3rem;
    grid-template-columns: minmax(0, 1fr);
    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @include media-breakpoint-up($bpUp) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  &-branding {
    display: flex;
    justify-content: center;
    margin: 3.4rem 0 0;
    @include media-breakpoint-up(lg) {
      margin: 0;
    }
    .logo img {
      filter: grayscale(1) brightness(5);
    }
  }
}
