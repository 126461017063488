.featured-agenda-items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.8rem;
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-up(lg) {
    gap: 2.8rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
// cover trick.
.cover-link {
  position: absolute;
  inset: 0;
  z-index: 15; // On top;
}

.agenda-item-card {
  overflow: hidden;
  background: var(--agenda-item-gradient);

  position: relative;
  color: white;
  min-height: 18rem;

  display: grid;
  grid-template-areas: "card";

  .agenda-item-card-byline {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    font-size: 1.6rem;
    line-height: calc(18 / 16);
    font-weight: 500;
    letter-spacing: 0.16rem;
    text-transform: uppercase;
    margin-block-end: 0.55rem;

    .agenda-item-date {
      margin-block-end: 1rem;
      @extend .h2;
    }
  }

  .index-image {
    grid-area: card;
    position: relative;
    aspect-ratio: 1/1.4;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: var(--agenda-item-gradient);
      mask-image: linear-gradient(transparent 0%, black 75%);
      background-blend-mode: multiply, normal;
      opacity: 0.75;
    }
  }

  .agenda-item-card-content {
    grid-area: card;
    align-self: flex-end;
    position: relative;
    z-index: 10;
    padding: 3rem;

    @include media-breakpoint-up($bpUp) {
      padding: 5rem 3.8rem;
    }
  }
}

[data-style-theme="corporate"],
[data-style-theme="school-circle"],
[data-style-theme="school-wave"] {
  .agenda-item-card {
    border-radius: var(--theme-border-radius, 2rem);
  }
}
