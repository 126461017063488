.plate--element + .plate--element__checklist_element {
  margin-block-start: var(--vertical-flow);
}
.checklist {
  column-count: var(--column-count, 1);
  gap: 1.6rem;

  .checklist-item {
    break-inside: avoid;
    display: flex;
    gap: 1.4rem;
    margin-block-end: 1.6rem;

    svg {
      fill: var(--theme-checklist-fill);
      margin: 3px 0 0px;
      @include box(2rem);
      flex: 0 0 auto;
    }
  }
}
.checklist-wrapper {
  container-type: inline-size;
  container-name: checklist;
}
// On small spaces always list items in one column.
@container checklist (max-width: 55rem) {
  .checklist {
    --column-count: 1 !important;
  }
}
