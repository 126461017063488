.file {
    &__drop {
        background-color: lightcyan;
        border: 2px dotted lightblue;
        border-radius: .8rem;
        position: relative;
        padding: 2.4rem;

        &:focus-within {
            border-style: solid;
        }

        &.has_file {
            border-color: rgba(68, 208, 124, 1);
            background-color: rgba(236, 253, 243, 1);
        }
    }
    .file-placeholder {
        justify-self: center;
        grid-area: placeholder;
        color: grey;
    }
    .filename {
        grid-area: label;
        justify-self: flex-end;
        text-decoration: underline;
        color: aquamarine;
    }
    &__remove {
        display: none;
    }

    &__overlay {
        pointer-events: none;
        padding: 0 1rem;

        display: grid;
        grid-template-areas: "label icon" "placeholder placeholder";
        gap: 1rem;
    }

    .file__drop--icon {
        color: orange;
        align-self: center;
        grid-area: icon;
        width: 3rem;
        height: 3rem;
        flex: 0 0 auto;
    }
    input {
        opacity: 0;
        inset: 0;
        position: absolute;

        cursor: pointer;
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .drag_over {
        border-style: dashed;
    }

    .has_file {
        .file__remove {
            display: flex;
            border: 2px solid rgba(0, 128, 0, 0.14);
            background: rgba(0, 128, 0, 0.14);
            color: rgb(0, 128, 0);
            border-radius: 100%;
            width: 2.2rem;
            height: 2.2rem;
        }
    }

    &__remove {
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 2rem;
        transform: translateY(-50%);
    }
}
