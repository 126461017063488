.pagination {
  display: flex;

  &.loadmore {
    margin-block-start: 4rem;
    justify-content: center;
    flex-wrap: wrap;

    gap: 2.3rem;
    .button {
      text-transform: uppercase;
      letter-spacing: 0.16rem;
    }
  }

  ul {
    display: flex;
  }
}
.filter-input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  --gap: 1rem;
  gap: var(--gap);
  justify-content: center;
  padding-inline: 1.5rem;
  padding-block: 3rem;

  .filter-checkbox {
    .filter__items__list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: var(--gap);

      .filter__items__list__item {
        position: relative;
        display: flex;

        label {
          color: rgb(19, 46, 99);
          font-size: 1.4rem;
          line-height: calc(18 / 14);
          padding: 1.4rem 1.8rem;
          border: 1px solid currentColor;
        }
        > input {
          position: absolute;
          inset: 0;
          opacity: 0;
        }
        &--selected {
          label {
            border-color: rgb(19, 46, 99);
            background: rgb(19, 46, 99);
            color: white;
          }
        }
      }
    }
  }
}

[data-style-theme="corporate"],
[data-style-theme="school-circle"],
[data-style-theme="school-wave"] {
  .filter-input .filter-checkbox .filter__items__list .filter__items__list__item label {
    border-radius: var(--theme-border-radius, 3rem);
  }
}
