.header-colors {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 4;
  display: flex;
  width: 100%;
  > span {
    display: flex;
    height: 1.25rem;
    width: 100%;
  }
  .asg-blauw {
    background: var(--asg-blauw);
  }
  .asg-groen {
    background: var(--asg-groen);
  }
  .asg-rood {
    background: var(--asg-rood);
  }
  .asg-paars {
    background: var(--asg-paars);
  }
}
