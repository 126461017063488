.school-impression {
  &-grid {
    display: grid;
    gap: 2rem 3rem;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: max-content;
    @include media-breakpoint-up($bpUp) {
      grid-template-columns: repeat(10, minmax(0, 1fr));
      grid-template-rows: repeat(8, 1fr);
    }
  }
  .school {
    &-apply,
    &-first-image img,
    &-second-image img {
      border-radius: 3.5rem;
      overflow: hidden;
    }
    &-first-image,
    &-second-image {
      position: relative;
      height: 100%;
      width: 100%;
      img {
        @include media-breakpoint-up($bpUp) {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
        }
      }
    }
    &-apply {
      background: var(--theme-gradient);
      padding: 2rem 2.5rem;
      grid-row: 4;
      margin: -5rem 5rem 0;
      z-index: 10;
      position: relative;
      @include media-breakpoint-up($bpUp) {
        grid-column: 1 / 4;
        grid-row: 5 / 7;
        margin: 0;
      }
      &-title {
        font-size: 2.2rem;
        margin: 0 0 1rem;
        text-align: center;
        color: var(--white);
      }
      .button {
        svg {
          @include box(1.5rem);
        }
      }
    }
    &-logo {
      grid-row: 1;
      padding: 0 0 2rem;
      @include media-breakpoint-up($bpUp) {
        padding: 0 5rem 0 0;
        grid-column: 7 / 11;
        grid-row: 1 / 4;
      }
      img {
        margin: 0 auto;
        @include media-breakpoint-up($bpUp) {
          margin: 0;
        }
      }
    }
    &-first-image {
      z-index: 1;
      grid-row: 3;
      @include media-breakpoint-up($bpUp) {
        grid-column: 2 / 7;
        grid-row: 1 / 5;
      }
    }
    &-second-image {
      position: relative;
      grid-row: 2;
      @include media-breakpoint-up($bpUp) {
        grid-column: 4 / 11;
        grid-row: 4 / 9;
      }
      .image {
        width: 100%;
        height: 100%;
      }
      .label {
        position: absolute;
        right: 2.5rem;
        top: 0;
        transform: translateY(-50%);
        background-color: var(--first-color, var(--asg-blauw));
        color: var(--white);
        padding: 1.4rem 2.4rem;
        border-radius: 5rem;
        @include flex-c;
        gap: 1rem;
        z-index: 3;
        font-size: 1.6rem;
        @include media-breakpoint-up($bpUp) {
          right: 3.7rem;
        }
        svg {
          @include box(1.5rem);
        }
      }
    }
  }
}
