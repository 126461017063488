.school-info-tag {
  border-radius: 5rem;
  padding: 1.4rem 2.6rem;
  @include flex-c;
  gap: 0.8rem;
  .icon {
    @include flex-c;
  }
  &.blue {
    background-color: var(--asg-blauw);
    color: var(--white);
  }
  svg {
    @include box(2rem);
  }
}
