.plate--element + .plate--element__google_map {
	margin-block-start: var(--vertical-flow);
}
.google-map-wrapper {
	position: relative;
	z-index: 1;
	display: flex;
	width: 100%;

	.google-map-iframe {
		display: flex;
		border: none;
		width: 100%;
		height: 100%;
	}
}
